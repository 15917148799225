import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { triggerModal } from '../../../../_actions/common';
import { connect } from 'react-redux';
import _ from 'lodash'

function TransactionTable({
    row,
    triggerModal,
    ...props
}){
    return (
        <TableRow
            hover
            tabIndex={-1}
            key={row.id}
        >
            <TableCell align="left" className="max-w25">
                <div className="d-flex flex-row py-0 align-items-center">
                    <div className="fsize-16 fw-500">{row.id}</div>
                </div>
            </TableCell>
            <TableCell align="left" className="max-w15">
                <div className="d-flex flex-row py-0 align-items-center">
                    <div className="fsize-16 fw-500">{row.voucherInstance && row.voucherInstance.voucherCode ? _.trim(row.voucherInstance.voucherCode).replace("dd_voucher_", "") : "-"}</div>
                </div>
            </TableCell>
            <TableCell align="left" className="max-w15">
                <div className="d-flex flex-row py-0 align-items-center">
                    <div className="fsize-16 fw-500">{row.user && row.user.name ? row.user.name : "-"}</div>
                </div>
            </TableCell>
            <TableCell align="left" className="max-w15">
                <div className="d-flex flex-row py-0 align-items-center">
                    <div className="fsize-16 fw-500">{row.updatedAt ? new Date(row.updatedAt).toLocaleDateString() : "-"}</div>
                </div>
            </TableCell>
            <TableCell align="left" className="max-w15">
                <div className="d-flex flex-row py-0 align-items-center">
                    <div className="fsize-16 fw-500">{row.transactionAmountInSmallestUnit / 100} <span style={{textTransform:"uppercase"}}>{row.currencyCode}</span></div>
                </div>
            </TableCell>
            {/* <TableCell align="left" className="max-w20">
                <div className="d-flex">
                    <div className="mx-auto d-flex align-items-center">
                        <Button className="toLower color-1-imp rmv-outline" onClick={e => {
                            triggerModal({
                                show: true,
                                hide: true,
                                data: row,
                                type: CONSTANTS.routeType.TRANSACTION,
                                subtype: CONSTANTS.operation.VIEW,
                                size: "md"
                            })
                        }}>View Details</Button>
                    </div>
                </div>
            </TableCell> */}
        </TableRow>
    )
}

export default connect(null, { triggerModal })(TransactionTable)