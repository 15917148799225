import {
    LOADING,
    LOADED
} from './../_actions/type'

export default function (state = false, action) {
    const { type } = action

    switch (type) {
        case LOADING:
            return true

        case LOADED:
            return false

        default:
            return false
    }
}