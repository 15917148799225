import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { decreaseStepper } from '../../_actions/auth';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    iconContainer: {
        width: "80px",
        height: "80px"
    },
    labelContainer: {
        marginLeft: "10px"
    },
    verticalConnector: {
        padding: '0 0 22px',
        marginLeft: '8px'
    },
    iconRoot: {
        width: "inherit",
        height: "inherit",
        "&.MuiStepIcon-active": {
            fill: "#3DD797 !important"
        },
        "&.MuiStepIcon-completed": {
            fill: "#3DD797 !important"
        }
    },
    [theme.breakpoints.down('sm')]: {
        iconContainer: {
            width: "50px",
            height: "50px"
        }
    }
}));

function getSteps() {
    return ['Company Details', 'Company Address', 'Account Details'];
}

function VerticalLinearStepper({
    step,
    decreaseStepper
}) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    useEffect(() => {
        setActiveStep(step - 2)
    }, [step])

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={label} onClick={e => index < activeStep ? decreaseStepper(2, index) : ""} style={{cursor:"pointer"}}>
                        <StepLabel 
                        classes={{
                            iconContainer: classes.iconContainer, 
                            labelContainer: classes.labelContainer
                        }}
                        StepIconProps={{
                            classes: {
                                root: classes.iconRoot
                            }
                        }}
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}

export default connect(null, { decreaseStepper })(VerticalLinearStepper)