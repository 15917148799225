import { combineReducers } from 'redux';
import records from './records'
import auth from './auth'
import loader from './loader'
import tools from './tools'
import alert from './alert'
import refresh from './refresh'

export default combineReducers({
    records,
    auth,
    loader,
    tools,
    alert,
    refresh
});
  