import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    iconRoot: {
        "&.MuiStepIcon-active": {
            fill: "#3DD797 !important"
        },
        "&.MuiStepIcon-completed": {
            fill: "#3DD797 !important"
        }
    }
}));

export default function HorizontalStepper({
    stepNumber,
    getSteps
}) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps;

    useEffect(() => {
        setActiveStep(--stepNumber)
    }, [stepNumber])

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps && steps.length && steps.map((label) => (
                    <Step key={label}>
                        <StepLabel 
                        StepIconProps={{
                            classes: {
                                root: classes.iconRoot
                            }
                        }}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}
