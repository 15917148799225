import React from 'react'
import TableHead from '@material-ui/core/TableHead';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withRouter } from 'react-router-dom';
// import _ from 'lodash';
import TableHeading from '../../../../_config/table'

function EnhancedTableHead({
    routeType,
    userDetails,
    ...props
}) {
    // const { type } = props;
    let headCells = [...TableHeading.table[routeType]['columns']]

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric === 1 ? "center" : (headCell.numeric === 2 ? 'right' : 'left')}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default withRouter(EnhancedTableHead)