import axios from 'axios'
import { customStartWith } from '../_utils/_handlers'
import _, { head } from 'lodash'

class ApiBuilder {
    log = true
    defaultHeaders = {
        // ContentType: 'application/json'
    }

    constructor(slug = '', headers = {}) {
        this.slug = slug
        this.headers = headers || this.defaultHeaders
    }

    responseBuilder(response) {
        let hasError = false
        let buildObj = {}
        if(customStartWith(response?.status, 4) || customStartWith(response?.status, 5)) {
            hasError = true
            buildObj.data = response?.data || {}
            buildObj.globalMessage = customStartWith(response?.status, 4) ? "Bad Request" : "Internal Server Error"
            // buildObj.renderMessage = response?.data?.message || response?.data?.error || "Some Error Occurred, please try again"
            buildObj.renderMessage = response.status === 400 ? "Some Error Occurred, please try again" : (response.status === 500 ? "Internal Server Error" : (response?.data?.message || response?.data?.error || "Some Error Occurred, please try again"))
            // buildObj.renderMessage = response?.data?.message || response?.data?.error || "Some Error Occurred, please try again"
        }
        if(!response) {
            hasError = true
            buildObj.globalMessage = "Network Error"
            buildObj.renderMessage = "Some Error Occurred, please try again"
        }
        if(response?.data?.count) {
            buildObj.count = response.data.count
        }
        return {
            hasError,
            data: response?.data?.data || response?.data || {},
            ...buildObj
        }
    }

    fetchOneOrAll(slug = '', headers = {}) {
        return new Promise(async (resolve, reject) => {
            try {
                if(slug) {
                    this.slug = slug
                }
                const response = await axios.get(this.slug, {...this.headers, ...headers})
                this.logger("fetchOneOrAll:", response)
                resolve(this.responseBuilder(response))
            }
            catch(error) {
                this.logger("fetchOneOrAll: Error: ", [error.response, error], 0)
                reject(this.responseBuilder(error?.response || error))
            }
        })
    }

    multipleRequests(requests = []) {
        let createRequestPayload = _.map(requests, o => {
            if(o.type === "POST") {
                return axios.post(o.slug)
            }
            if(o.type === "GET") {
                return axios.get(o.slug)
            }
        })
        return axios.all(createRequestPayload).then(response => {
            return response;
        }).catch(error => {
            this.logger("Multiple Request: ", error, 0)
        })
    }

    post(data, header = {}, slug = null) {
        if(header && !_.isEmpty(header)) {
            this.headers = header
        }
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(slug || this.slug, data, this.headers)
                this.logger("Post:", response)
                resolve(this.responseBuilder(response))
            }
            catch(error) {
                this.logger("Post Request: Error: ", error, 0)
                reject(this.responseBuilder(error?.response || error))
            }
        })
    }

    patch(data, header = {}, slug = null) {
        if(header && !_.isEmpty(header)) {
            this.headers = header
        }
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.patch(slug || this.slug, data, this.headers)
                this.logger("Patch:", response)
                resolve(this.responseBuilder(response))
            }
            catch(error) {
                this.logger("Patch Request: Error: ", error, 0)
                reject(this.responseBuilder(error?.response || error))
            }
        })
    }

    logger(fn, message, type = -1) {
        if(this.log) {
            switch(type) {
                case 0: {
                    console.error(`Function: ${fn}`, message)
                    break;
                }
                default: {
                    console.log(`Function: ${fn}`, message)
                }
            }
        }
    }
}

export default ApiBuilder