import {
    RESET_RECORDS
} from './../_actions/type'

export default function (state = [], action) {
    const { type, payload } = action

    switch (type) {
        case RESET_RECORDS:
            return payload

        default:
            return []
    }
}