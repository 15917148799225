import React, { useEffect, useState } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import OrganisationIndex from '../../components/organisation'
import Header from '../../components/reusable/_header'
import { CONSTANTS } from '../../_utils/_constants'
import ModalIndex from '../../components/modal/index'
import AddStore from '../../components/organisation/store/add'
import PrivateRoute from './../../routes/index'
import FormIndex from '../../components/organisation/form'

function OrganisationComponent({
    match,
    ...rest
}) {
    const [activatedRoute, setActivatedRoute] = useState(`/dashboard`)
    const currentURL = `${window.location.pathname}${window.location.search}`
    const relativePath = match.url

    useEffect(() => {
        if(currentURL) {
            setActivatedRoute(`${currentURL}`)
        }
    }, [match.params, currentURL])

    return (
        <React.Fragment>
            <Header routeType={"organisation"}/>
            <Switch>
                <PrivateRoute path={`${relativePath}/:routeType(${CONSTANTS.routeType.STORE})/:storeType(physical|online)/:operationType(add)`} exact component={AddStore} />
                <PrivateRoute path={`${relativePath}/:routeType(${CONSTANTS.routeType.STORE})/:resourceId`} exact component={FormIndex} />
                <PrivateRoute path={`${relativePath}/:parentType(${CONSTANTS.routeType.STORE})/:routeType(employees|transaction)/:resourceId`} 
                exact component={OrganisationIndex} />
                <PrivateRoute path={`${relativePath}/:routeType(${CONSTANTS.routeType.PROFILE})`} exact component={FormIndex} />
                <PrivateRoute path={`${relativePath}`} exact component={OrganisationIndex}/>
                <Redirect to={`${activatedRoute}`}/>
            </Switch>
            <ModalIndex/>
        </React.Fragment>
    )
}

export default OrganisationComponent