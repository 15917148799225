import { CONSTANTS } from "../_utils/_constants";
import _ from 'lodash'

export default {
    topHead: {
        [CONSTANTS.routeType.HOME]: params => {
            return {
                title: params?.auth?.user?.companyName || "Organization Name",
                subtitle: "List of Stores",
                button: {
                    name: 'Add Store',
                    img: 'dstore.svg',
                    drop: true,
                    children: [
                        {
                            name: 'Physical Store',
                            redirect: true,
                            link: CONSTANTS.store.physical
                        },
                        {
                            name: 'Virtual Store',
                            redirect: true,
                            link: CONSTANTS.store.online
                            // redirect: false,
                            // type: CONSTANTS.routeType.ONSTORE
                        }
                    ]
                },
                backButton: false
            }
        },
        [CONSTANTS.routeType.EMPLOYEE]: (params) => {
            let location = {
                state: params.records?.constants?.listLocations && params.storeDetails && params.storeDetails.locationCode ? _.find(params.records?.constants?.listLocations, { code: params.storeDetails.locationCode })['state'] : "",
            }
            if(location.state) {
                location.country = "United States of America"
            }
            return {
                // title: params.storeDetails && params.storeDetails.storeName ? params.storeDetails.storeName : "Store Name",
                title: "Employee Management",
                subtitle: params.storeDetails && params.storeDetails.storeName ? `${params.storeDetails.storeName} ${location.country ? "-" : ""} ${Object.values(location).join(", ")}` : "",
                button: {
                    name: 'Add Employee',
                    img: 'person.svg',
                    drop: false,
                    redirect: false,
                    type: CONSTANTS.routeType.EMPLOYEE
                },
                backButton: true
            }
        },
        [CONSTANTS.routeType.TRANSACTION]: params => {
            let location = {
                state: params.records?.constants?.listLocations && params.storeDetails && params.storeDetails.locationCode ? _.find(params.records?.constants?.listLocations, { code: params.storeDetails.locationCode })['state'] : "",
            }
            if(location.state) {
                location.country = "United States of America"
            }
            return {
                title: 'Transaction History',
                subtitle: params.storeDetails && params.storeDetails.storeName ? `${params.storeDetails.storeName} ${location.country ? "-" : ""} ${Object.values(location).join(", ")}` : "",
                backButton: true
            }
        },
        [CONSTANTS.routeType.PYSTORE]: () => {
            return {
                title: 'Add a New Store',
                backButton: true
            }
        },
        [CONSTANTS.routeType.PROFILE]: params => {
            let button = {
                name: 'Edit Details',
                mi: 'create',
                drop: false,
                redirect: false,
                type: CONSTANTS.routeType.PROFILE
            }
            if(params.reduxTools && params.reduxTools.editMode) {
                button = { name: "Save Details", buttonType: "submit", mi: "done" }
            }
            return {
                title: 'Organization Profile',
                // button: {...button},
                backButton: true
            }
        },
        [CONSTANTS.routeType.STORE]: params => {
            let button = {
                name: 'Edit Details',
                mi: 'create',
                drop: false,
                redirect: false,
                type: CONSTANTS.routeType.PYSTORE
            }
            if(params.reduxTools && params.reduxTools.editMode) {
                button = { name: "Save Details", buttonType: "submit", mi: "done" }
            }
            return {
                title: 'Store Details',
                subtitle: 'Find details of your store below',
                backButton: true,
                // button: {...button}
            }
        }
    }
}