import { LOGOUT, RESET_RECORDS, SET_RECORDS } from './../_actions/type'
import _ from 'lodash'

const initialState = {}

export default function(state = initialState, action){
    const {type, payload, subtype} = action

    switch(type){
        case SET_RECORDS :
            let records = {};
            if(subtype) {
                records = {
                    [subtype] : {
                        ...state[subtype],
                        [payload.type] : {...payload.data}
                    }
                }
            }
            else if(payload.type) {
                records = {
                    [payload.type] : {
                        ...state[payload.type], 
                        ...payload.data
                    }
                }
            }
            else {
                records = {...records, ...payload.data}
            }
            return {
                ...state,
                ...records
            }

        case RESET_RECORDS: {
            let newState = _.omit(state, payload)
            return {
                ...newState
            }
        }

        case LOGOUT: {
            return {
                constants: state.constants
            }
        }

        default :
            return state
    }
}