import React from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { NavLink } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

function ForgotPassword({
    inputHandler,
    formRef,
    submitForm,
    payload,
    classes,
    ...rest
}) {
    return (
        <div className="right">
            <div className="d-flex h-100 auth--container_right">
                <div className="my-auto w-100">
                    <h2 className="fsize-28">Let's Get Started</h2>
                    <h5 className="fsize-16">Reset Password with your email</h5>
                    <div className="mt-5">
                        <form autoComplete={"off"} ref={formRef} onSubmit={e => submitForm(e)} className="form">
                            <div className="w-100 mb-4 pb-1">
                                <TextField name="email" type="email" onChange={inputHandler} required
                                    id="email" label="Email" value={payload.fields.email || ""}
                                    variant="outlined" className={classes.textField} />
                            </div>
                            {
                                payload.forgotStep >= 1 ?
                                <>
                                <div className="w-100 mb-4">
                                    <TextField name="password" type="password" onChange={inputHandler} required
                                        id="password" label="Password" value={payload.fields.password || ""}
                                        variant="outlined" className={classes.textField} />
                                </div>
                                <div className="w-100 mb-4">
                                    <TextField name="confirm-password" type="password" onChange={inputHandler} required
                                        id="confirm-password" label="Confirm Password" value={payload.fields['confirm-password'] || ""}
                                        variant="outlined" className={classes.textField} error={payload.error['confirm-password'] ? true : false} 
                                        helperText={payload.error['confirm-password'] || ""}/>
                                </div>
                                <div className="w-100 mb-4">
                                    <TextField name="otp" type="number" onChange={inputHandler} required
                                        id="otp" label="Enter OTP" value={payload.fields.otp || ""}
                                        variant="outlined" className={classes.textField} />
                                </div>
                                </> : ""
                            }
                            <div className="btn-wrap d-flex justify-content-between w-100">
                                <Button type="submit" id="submitFormButton"
                                    variant="contained" className={`${classes.themeButton} w-100 py-2 br-2 transform-initial`}>
                                    {(!payload.forgotStep) ? 'Send OTP' : 'Change Password'}
                                </Button>
                                {/* { payload.showLoader && <CircularProgress size={24} className={classes.buttonProgress} />} */}
                            </div>
                        </form>
                    </div>
                </div>
                <div className="bottom-block">
                    <div className="d-flex align-items-center w-100 justify-content-center">
                        <span className="fsize-16 color-21">Don't Have an Account?</span>
                        <div className="text-center">
                            <NavLink className={classes.defaultNavLink} to={`/register`}>
                                <Button className={`${classes.themeButton} ${classes.lightThemeButton} ${classes.noPadding} my-2 color-1-imp toLower`}>
                                    Signup
                            </Button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword