import React from 'react';
import Container from '@material-ui/core/Container';

const Footer = () => {

    return (
        <footer>
            <Container>
                <div className="row py-5">
                    <div className="col-md-9">
                        <div className="d-flex align-items-center mb-3">
                            <div className="img-wrap">
                                <img src={`/logo-white.svg`} className="img-fluid" alt="Logo" style={{width:"150px"}}/>
                            </div>
                            {/* <h6 className="ml-3 text-white mb-0">Digi Donor</h6> */}
                        </div>
                        <div className="d-flex flex-column text-left text-white">
                            <h4>Empowering communities.</h4>
                            <h4>Ending Poverty</h4>
                        </div>
                    </div>
                    {/* <div className="col-md-3 mt-4 mt-md-0">
                        <div className="d-flex flex-column text-white">
                            <h6>Follow us on Social Media</h6>
                            <div className="d-flex justify-content-start social-footer">
                                <div className="mt-2">
                                    <a href="https://google.com/">
                                        <img src={`assets/icons/instagram.svg`} className="img-fluid" alt="Instagram"/>
                                    </a>
                                    <a href="https://google.com/">
                                        <img src={`assets/icons/facebook-circular.svg`} className="img-fluid" alt="Instagram"/>
                                    </a>
                                    <a href="https://google.com/">
                                        <img src={`assets/icons/linkedin.svg`} className="img-fluid" alt="Instagram"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </Container>
        </footer>
    );
}

export default Footer