import ApiBuilder from "../_utils/_apiBuilder"
import { CONSTANTS } from '../_utils/_constants';
import { LOADING, LOGOUT, SET_AUTH_SESSION, SET_RECORDS, SHOW_ALERT } from './type'
import _ from 'lodash'
import { capitalize } from "../_utils/_handlers";

export const userLogin = (payload = {}, callback) => async (dispatch, store) => {
    try {
        dispatch({
            type: LOADING
        })

        let loginPayload = {
            email: payload.email,
            password: payload.password
        }
        let apiInstance = new ApiBuilder(`organisation/login`)
        let response = await apiInstance.post({
            ...loginPayload
        })
        if(response) {
            const responsePayload = payloadOrganizer(response.data && response.data.token ? response.data.token : "", response)
            if(responsePayload.step === 1) {
                const otpSessionIdPayload = await apiInstance.fetchOneOrAll(CONSTANTS.url.organisation.resend, {
                    headers: {
                        Authorization: responsePayload.token
                    }
                })
                responsePayload.otpSessionId = otpSessionIdPayload.data && otpSessionIdPayload.data.otpSessionId ? otpSessionIdPayload.data.otpSessionId : -1
            }
            dispatch({
                type: SET_AUTH_SESSION,
                payload: responsePayload
            })
            localStorage.setItem(process.env.REACT_APP_TOKEN, responsePayload.token)
            if(responsePayload.step > CONSTANTS.registration.COMPANY_ADDRESS) {
                dispatch({
                    type: SHOW_ALERT,
                    message: "Logged In Successfully",
                    variant: "success"
                })
            }
            return callback(responsePayload)
        }
        return callback({
            hasError: true
        })
    }
    catch(e) {
        dispatch({
            type: SHOW_ALERT,
            message: e.renderMessage || e.globalMessage || "Some error occurred, please try again"
        })
        return callback(e)
    }
}

export const userSignup = (payload = {}, callback) => async (dispatch, store) => {
    try {
        if (store().auth.step >= CONSTANTS.registration.EMAIL_VERIFIED) {
            let responsePayload = store().auth
            let currentStep = store().auth.step
            responsePayload.step = store().auth.step === CONSTANTS.registration.EMAIL_VERIFIED ? CONSTANTS.registration.COMPANY_DETAILS : CONSTANTS.registration.COMPANY_ADDRESS
            let companyDetails = {}
            if (store().auth.companyDetails) {
                companyDetails = store().auth.companyDetails
            }
            responsePayload.companyDetails = { ...companyDetails, ...payload }
            dispatch({
                type: SET_AUTH_SESSION,
                payload: responsePayload
            })
            if (currentStep === CONSTANTS.registration.EMAIL_VERIFIED || currentStep === CONSTANTS.registration.COMPANY_DETAILS) {
                return callback(responsePayload)
            }
        }
        dispatch({
            type: LOADING
        })

        let customPayload = payload
        let headerPayload = {}
        const routeType = store().auth.step === undefined ? 'signup' : (store().auth.step === 1 ? 'verify' : (store().auth.step > 2 ? 'final' : ''))  
        let apiInstance = new ApiBuilder(CONSTANTS.url.organisation[routeType])

        if(routeType === "verify") {
            customPayload = {
                otp: payload.otp,
                otpSessionId: store().auth.otpSessionId
            }
        }
        if(routeType === "final" || routeType === "verify") {
            headerPayload = {
                headers: {
                    Authorization: store().auth.token
                }
            }
        }

        if(routeType === "final") {
            let stripe = window.Stripe(CONSTANTS.stripe_key);
            let stripeResponse = await stripe.createToken('bank_account', {
                country: "US",
                currency: "usd",
                account_holder_type: payload.account_holder_type,
                account_holder_name: payload.account_holder_name,
                account_number: payload.account_number,
                routing_number: payload.routing_number
            })

            if(stripeResponse.error) {
                dispatch({
                    type: SHOW_ALERT,
                    message: `Error: ${capitalize(stripeResponse.error.code)}`
                })
                return callback({
                    hasError: true,
                    ...stripeResponse
                })
            }

            let locationCode = store().records?.constants ? _.find(store().records.constants.listLocations, { state: store().auth.companyDetails.state })['code'] : ""
            let companyData = _.omit(store().auth.companyDetails, [ "companyLogoImage", "state", "country", "email", "password", "otp", "otpSessionId", "confirm-password", "confirm_routing_number", "confirm_account_number", "account_holder_type", "account_holder_name", "account_number", "routing_number" ])
            customPayload = new FormData()
            customPayload.append('data', JSON.stringify({
                bankAccountStripeTokenId: stripeResponse.token && stripeResponse.token.id ? stripeResponse.token.id : "",
                ...companyData,
                locationCode,
                contactPhoneNumber: `${_.includes(companyData.contactPhoneNumber, "+1") ? companyData.contactPhoneNumber : "+1" + companyData.contactPhoneNumber}`
            }))
            customPayload.append('companyLogoImage', store().auth.companyDetails && store().auth.companyDetails.companyLogoImage && store().auth.companyDetails.companyLogoImage.blobObj ? store().auth.companyDetails.companyLogoImage.blobObj : "")
        }

        if(routeType === "signup") {
            customPayload = {
                email: payload.email,
                password: payload.password
            }
        }

        let response = await apiInstance.post(customPayload, {...headerPayload})

        if(response) {
            let responsePayload = payloadOrganizer(store().auth.token || (response.data && response.data.token ? response.data.token : ""), response)
            dispatch({
                type: SET_AUTH_SESSION,
                payload: responsePayload
            })
            localStorage.setItem(process.env.REACT_APP_TOKEN, responsePayload.token)
            if(responsePayload.step > CONSTANTS.registration.COMPANY_ADDRESS) {
                dispatch({
                    type: SHOW_ALERT,
                    message: "Logged In Successfully",
                    variant: "success"
                })
            }
            return callback(responsePayload)
        }
        return callback({
            hasError: true
        })
    }
    catch(e) {
        dispatch({
            type: SHOW_ALERT,
            message: e.renderMessage || e.globalMessage || "Some error occurred, please try again"
        })
        return callback(e)
    }
}

export const userForgotPassword = (payload = {}, callback) => async (dispatch, store) => {
    try {
        dispatch({
            type: LOADING
        })
        let customPayload = {}
        let customResponse = {}
        let verifyOtp = false
        let customMessage = "OTP sent to your registered Email Address"
        if(store().auth.forgotPassword && store().auth.forgotPassword.otpSessionId) {
            verifyOtp = 2
            customPayload = {
                otpSessionId: store().auth.forgotPassword.otpSessionId,
                otp: payload.otp,
                newPassword: payload.password
            }
            customMessage = "Password Reset Successful"
            customResponse = await new ApiBuilder(CONSTANTS.url.organisation.reset).post(customPayload)
            dispatch({
                type: SET_AUTH_SESSION,
                payload: {}
            })
        }
        else {
            verifyOtp = 1
            customPayload.email = payload.email
            customResponse = await new ApiBuilder(`${CONSTANTS.url.organisation.forgot}?email=${payload.email}`).fetchOneOrAll()
            console.log(customResponse)
            dispatch({
                type: SET_AUTH_SESSION,
                payload: {
                    ...store().auth,
                    forgotPassword: {
                        otpSessionId: customResponse.data && customResponse.data.otpSessionId ? customResponse.data.otpSessionId : ""
                    }
                }
            })
        }

        dispatch({
            type: SHOW_ALERT,
            message: customMessage
        })

        return callback({
            forgotStep: verifyOtp,
            ...customResponse
        })
    } 
    catch (error) {
        dispatch({
            type: SHOW_ALERT,
            variant: "error",
            message: error.renderMessage || error.globalMessage || "Some error occurred, please try again"
        })
        return callback(error)
    }
}

export const loadUser = (callback, showLoader = true) => async (dispatch, store) => {
    try {
        let token = localStorage.getItem(process.env.REACT_APP_TOKEN)
        if(!token) {
            return callback(false)
        }
        if(showLoader) {
            dispatch({
                type: LOADING
            })
        }
        const apiInstance = new ApiBuilder(`organisation/me`, {
            headers: {
                Authorization:`${token}`
            }
        })
        const response = await apiInstance.fetchOneOrAll()
        
        if(response) {
            const responsePayload = payloadOrganizer(token, response)
            if(responsePayload.step === 1) {
                const otpSessionIdPayload = await apiInstance.fetchOneOrAll(CONSTANTS.url.organisation.resend)
                responsePayload.otpSessionId = otpSessionIdPayload.data && otpSessionIdPayload.data.otpSessionId ? otpSessionIdPayload.data.otpSessionId : -1
            }
            dispatch({
                type: SET_AUTH_SESSION,
                payload: responsePayload
            })
            return callback(responsePayload)
        } 
        return callback({
            hasError: true
        })
    }
    catch(e) {
        localStorage.removeItem(process.env.REACT_APP_TOKEN)
        dispatch({
            type: SHOW_ALERT,
            variant: "error",
            message: e.renderMessage || e.globalMessage || "Some error occurred, please try again"
        })
        return callback(e)
    }
}

export const loadConstants = () => async dispatch => {
    try {
        let constants = await new ApiBuilder(`categories-and-constants`).fetchOneOrAll()
        dispatch({
            type: SET_RECORDS,
            payload: {
                type: "constants",
                data: constants.data
            }
        })
    } 
    catch (error) {}
}

export const logoutHandler = () => async (dispatch, store) => {
    localStorage.removeItem(process.env.REACT_APP_TOKEN)
    dispatch({
        type: LOGOUT
    })
    dispatch({
        type: SHOW_ALERT,
        message: "Logout Successfully",
        variant: "success"
    })
}

export const decreaseStepper = (stepCount, currentStep = 1) => async (dispatch, store) => {
    dispatch({
        type: SET_AUTH_SESSION,
        payload: {
            ...store().auth,
            step: currentStep + stepCount
        }
    })
}

function payloadOrganizer(token, response) {
    let responsePayload = {
        token,
        user: response.data && response.data.organisation ? {...response.data.organisation} : {},
        step: response.data && response.data.organisation && response.data.organisation.registrationStatus === "UNREGISTERED" ? CONSTANTS.registration.UNREGISTERED : 
        (response.data.organisation && response.data.organisation.registrationStatus === "EMAIL_VERIFIED" ? CONSTANTS.registration.EMAIL_VERIFIED : 
        (response.data.organisation && response.data.organisation.registrationStatus === "REGISTERED" ? CONSTANTS.registration.FINAL : "")),
        isVerified: response.data && response.data.organisation && response.data.organisation.isVerified
    }
    if(response.data && response.data.otpSessionId) {
        responsePayload['otpSessionId'] = response.data.otpSessionId
    }
    return responsePayload
}

// function sleep(delay = 0) {
//     return new Promise((resolve) => {
//         setTimeout(resolve, delay);
//     });
// }