import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { NavLink } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import { CONSTANTS } from '../../../../_utils/_constants';

function HomeTable({
    row,
    ...props
}){
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <TableRow
            hover
            tabIndex={-1}
            key={row.id}
        >
            <TableCell align="left" className="max-w25">
                <div className="d-flex flex-row py-0 align-items-center">
                    <div className="fsize-16 fw-500">{row.storeName}</div>
                </div>
            </TableCell>
            <TableCell align="left" className="max-w20">
                <div className="d-flex flex-row py-0 align-items-center">
                    <div className="fsize-16 fw-500">{row.storeIdentificationCode}</div>
                </div>
            </TableCell>
            <TableCell align="left" className="max-w20">
                <div className="d-flex flex-row py-0 align-items-center">
                    <div className="fsize-16 fw-500" style={{ whiteSpace: "nowrap" }}>{row.isVirtualStore ? "Virtual Store" : `${row.officeAndBuildingNumber}${row.streetOrLocalityName ? `, ${row.streetOrLocalityName}` : ''}`}</div>
                </div>
            </TableCell>
            <TableCell align="left" className="max-w40">
                <div className="d-flex">
                    <div className="mx-auto d-flex align-items-center">
                        <NavLink className={"mr-2 defNavLink fw-500"} to={`/dashboard/${CONSTANTS.routeType.STORE}/employees/${row.id}`}>View Employees</NavLink>
                        <NavLink className={"mx-2 defNavLink fw-500"} to={`/dashboard/${CONSTANTS.routeType.STORE}/transaction/${row.id}`}>View Transactions</NavLink>
                        <span aria-describedby={id} variant="contained" color="primary" onClick={handleClick} 
                        className="material-icons color-1-imp cp ml-3">info_outline</span>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div className="py-2 px-3">
                                Edit Store Details <NavLink className={"defNavLink"} to={`/dashboard/${CONSTANTS.routeType.STORE}/${row.id}`}>Here</NavLink>.
                            </div>
                        </Popover>
                    </div>
                </div>
            </TableCell>
        </TableRow>
    )
}

export default HomeTable