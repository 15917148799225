import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import throttle from 'lodash/throttle';
import { HomePageStyles } from '../../_styles/mui';
import { CONSTANTS } from '../../_utils/_constants';
import _ from 'lodash';
import { Avatar } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const indexStyles = theme => HomePageStyles(theme)

export default function SearchEmployee({
    className,
    handler,
    payload,
    fetchRecords,
    s3BaseUrl,
    required = true
}) {
    const indexClass = indexStyles();
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false)

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                if(request.input && request.input.length > 4) {
                    setLoading(true)
                    fetchRecords({
                        routeType: CONSTANTS.routeType.EMPLOYEE,
                        operationType: CONSTANTS.operation.SEARCH,
                        search: request.input
                    }, cb => {
                        setLoading(false)
                        callback(cb)
                    }, false)
                }
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results && results.data) {
                    newOptions = [...newOptions, ...results.data];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            id="search-employee"
            className={`${className}`}
            style={{ width: '100%' }}
            getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name}`)}
            filterOptions={(x) => x}
            options={options || []}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
                console.log(newValue)
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                handler({ target: { type: "select", name: "selectedEmployee", value: newValue } }, "MuiSelect")
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => {
                console.log(params)
                return (
                    <TextField {...params} label="Search Employee" 
                    required={required} value={payload.selectedEmployee && payload.selectedEmployee ? payload.selectedEmployee : ""}
                    variant="outlined" fullWidth name={"selectedEmployee"}
                    className={`${indexClass.textField} customInputClass`}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading && <CircularProgress color="inherit" size={20} />}
                            </React.Fragment>
                        )
                    }}
                    />
                )
            }}
            renderOption={(option) => {
                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            {
                                option.profileImageUri ? 
                                <img src={`//${s3BaseUrl}${option.profileImageUri}`} alt={option.name} className="img-fluid avatar-img"/> : 
                                <Avatar className={`bg-1-imp avatar-img`}>{option.name ? _.trim(option.name).substr(0,1) : ""}</Avatar>
                            }
                        </Grid>
                        <Grid item xs className="ml-2">
                            <span style={{ fontWeight: 700 }}>
                                {option.name}
                            </span>
                            <Typography variant="body2" color="textSecondary">
                                {option.phoneNumber}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {option.email}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}
