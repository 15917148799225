import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function StripeAlert({
    payload,
    type
}) {
    const classes = useStyles();

    return (
        <React.Fragment>
            {
            payload && !_.isEmpty(payload) && (!_.isEmpty(payload.listPendingRequirements) || !_.isEmpty(payload.listErrorRequirements)) &&
            <div className={`mb-5 ${classes.root}`}>
                <Alert severity="error">
                    <AlertTitle>
                    This { type === 1 ? 'Organisation' : (type === 2 ? 'store' : "-")} could not be verified due to the following reasons. Please update the following details to enable payments.
                    </AlertTitle>
                {
                    payload && Object.keys(payload).map(e => {
                        let go = false
                        if(e === "listErrorRequirements" && payload[e] && payload[e].length > 0) {
                            go = true
                        }
                        return (
                            <div className="mt-3" key={e}>
                                <div className="my-1"><strong style={{fontSize:"15px"}}>{e === "listPendingRequirements" ? "Pending Requirements" : "Verification Errors"}</strong></div>
                                { e === "listPendingRequirements" && Object.values(payload[e]).join(", ") }
                                { e === "listErrorRequirements" && go && Object.values(payload[e]).map((o,z) => {
                                    return (
                                        <div key={z} className="d-block">
                                            <strong>{o.requirement}</strong> : {o.reason}
                                        </div>
                                    )
                                }) }
                            </div>
                        )
                    })
                }
                </Alert>
            </div>
            }
        </React.Fragment>
    )
}

export default StripeAlert