import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';
import EnhancedTableHead from './config/head'
import { queryParamsToObject, queryParamsToString } from '../../../_utils/_handlers';
import { connect } from 'react-redux';
import _ from 'lodash'
import BodyIndex from './config/body';
import { fetchRecords } from '../../../_actions/common';
import { CONSTANTS } from '../../../_utils/_constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function TableIndex({
  routeType,
  match,
  history,
  fetchRecords,
  hardRefresh,
  ...rest
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  // const [errorHandler, setErrorHandler] = useState({})
  const [errorHandler] = useState({})
  const [totalCount, setTotalCount] = useState(0)

  useEffect(() => {
    init()
  }, [routeType, history.location.search])

  useEffect(() => {
    if (hardRefresh && !_.isEmpty(hardRefresh) && _.isArray(hardRefresh) && hardRefresh.includes(match.params.routeType)) {
      init()
    }
  }, [hardRefresh])

  const init = () => {
    setPage(0)
    setTotalCount(0)
    setRows([])
    setLoading(true)
    let queryParams = queryParamsToObject(history.location.search)
    let pg = queryParams['pageNumber'] !== undefined ? ((queryParams['pageNumber'] * 1) - 1) : 0
    fetchRecords({
      routeType,
      ...match.params,
      // page: pg + 1,
      ...queryParams,
      operationType: CONSTANTS.operation.LIST
    }, callback => {
      if (!callback.hasError) {
        setRows([...callback.data])
        setTotalCount(callback.count || callback.data.length)
        setPage(pg)
      }
      setLoading(false)
    })
  }

  const handleChangePage = (event, newPage) => {
    let params = queryParamsToObject(history.location.search)
    params = _.omit(params, ['type'])
    params['pageNumber'] = newPage + 1;
    params['createdAtFirstItem'] = rows && rows.length > 0 ? rows[0]['createdAt'] : ""
    if (newPage === 0) {
      params = _.omit(params, ['pageNumber', 'createdAtFirstItem'])
    }
    history.push({
      search: queryParamsToString({ ...params })
    })
    return;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <div className={`${classes.root} pt-4 ${rows.length <= 0 ? "loading-table" : ""}`}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                routeType={routeType}
                classes={classes}
                rowCount={rows.length}
                type={match.params.type}
              />
              <BodyIndex
                rows={rows}
                loading={loading}
                routeType={routeType}
                errorHandler={errorHandler}
                rowsPerPage={rowsPerPage} />
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    userDetails: state.auth.user || {},
    hardRefresh: state.refresh || []
  }
}

export default connect(mapStateToProps, { fetchRecords })(withRouter(TableIndex))