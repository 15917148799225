// import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const styleList = {
    headerRoot: {
        flexGrow: 1
    },
    title: {
        flexGrow: 1,
        color: "var(--color-xl)",
        letterSpacing: "0.05rem"
    },
    appBar: {
        backgroundColor: "var(--color-xxs) !important",
        boxShadow: "0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02) !important",
        padding: "2px 20px",
        position: "relative"
    },
    themeButton: {
        backgroundColor: "var(--color-1) !important",
        color: "var(--color-xxs) !important",
        padding: "12px 25px !important",
        borderRadius: "8px",
        fontSize: "16px !important",
        "&:hover": {
            backgroundColor: "var(--color-1)",
            opacity: "0.85"
        },
        "&:active, &:focus, &:hover": {
            outline: "0 !important"
        },
        "&:disabled": {
            opacity: 0.5,
            cursor: "not-allowed"
        }
    },
    themeButtonEp: {
        padding: "12px 60px !important"
    },
    lightThemeButton: {
        backgroundColor: "var(--color-xxs) !important",
        color: "var(--color-xl) !important",
        "&:hover": {
            backgroundColor: "var(--color-xxs) !important",
            opacity: "1 !important"
        }
    },
    colorThemeButton: {
        color: "var(--color-1) !important"
    },
    noPadding: {
        padding: "0 !important"
    },
    defaultNavLink: {
        color: "var(--color-xl)",
        textDecoration: "none !important",
        "&:hover": {
            color: "var(--color-md)"
        }
    },
    hoverNavLink: {
        '&:hover': {
            opacity: "0.85",
            color: "var(--color-1)"
        }
    },
    primaryColorText: {
        color: "var(--color-1)"
    },
    homeCoverImage: {
        minHeight: "500px",
        maxHeight: "calc(100vh - 250px)",
        height: "calc(100vh - 250px)",
        background: 'var(--bg-cover-gradient), url(/assets/background/home_cover.svg)',
        backgroundSize: 'cover'
    },
    lgColorText: {
        color: "var(--color-md)"
    },
    cardWrap: {
        boxShadow: "var(--card-shadow)",
        border: "0",
        minHeight: "230px",
        borderRadius: "10px",
        height: "100%"
    },
    textField: {
        width: "100%",
        '& label.Mui-focused': {
            color: 'var(--color-1)',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'var(--color-1)',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'var(--color-lg)',
            },
            '&:hover fieldset': {
                borderColor: 'var(--color-lg)',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'var(--color-1)',
            },
        },
    },
    buttonProgress: {
        color: 'white !important',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    customMenuItem: {
        
    }
}

const themeList = (theme) => {
    return {
        [theme.breakpoints.down('sm')]: {
            appBar: {
                padding: "5px 0"
            },
            themeButton: {
                padding: "10px 20px"
            }
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120
        }
    }
}

export const headerStyles = makeStyles((theme) => ({
    themeButton: {...styleList.themeButton},
    appBar: {...styleList.appBar},
    title: {...styleList.title},
    headerRoot: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    lightThemeButton: {...styleList.lightThemeButton},
    defaultNavLink: {...styleList.defaultNavLink},
    ...themeList(theme)
}));

export const HomePageStyles = makeStyles((theme) => ({
    homeCoverImage: {...styleList.homeCoverImage},
    defaultNavLink: {...styleList.defaultNavLink},
    themeButton: {...styleList.themeButton},
    'themeButton-ep': {...styleList.themeButtonEp},
    lightThemeButton: {...styleList.lightThemeButton},
    primaryColorText: {...styleList.primaryColorText},
    lgColorText: {...styleList.lgColorText},
    cardWrap: {...styleList.cardWrap},
    textField: {...styleList.textField},
    hoverNavLink: {...styleList.hoverNavLink},
    buttonProgress: {...styleList.buttonProgress},
    customMenuItem: {...styleList.customMenuItem},
    colorThemeButton: {...styleList.colorThemeButton},
    noPadding: {...styleList.noPadding},
    formControl: {...styleList.formControl},
    ...themeList(theme)
}))