import React from 'react'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { NavLink } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

function LoginComponent({
    inputHandler,
    formRef,
    submitForm,
    payload,
    classes,
    ...rest
}) {
    return (
        <div className="right">
            <div className="d-flex h-100 auth--container_right">
                <div className="my-auto w-100">
                    <h2 className="fsize-28">Let's Get Started</h2>
                    <h5 className="fsize-16">Login with your email and password</h5>
                    <div className="mt-5">
                        <form autoComplete={"off"} ref={formRef} onSubmit={e => submitForm(e)} className="form">
                            <div className="w-100 mb-4 pb-1">
                                <TextField name="email" type="email" onChange={inputHandler} required
                                    id="email" label="Email" value={payload.fields.email || ""}
                                    variant="outlined" className={classes.textField} />
                            </div>
                            <div className="w-100 mb-4">
                                <TextField name="password" type="password" onChange={inputHandler} required
                                    id="password" label="Password" value={payload.fields.password || ""}
                                    variant="outlined" className={classes.textField} />
                            </div>
                            {/* <FormControlLabel
                                className="mb-2 pb-2"
                                value="1"
                                control={
                                    <Checkbox color="default" />
                                }
                                label="Keep me logged in"
                                labelPlacement="end"
                            /> */}
                            <div className="btn-wrap d-flex justify-content-between w-100">
                                <Button type="submit" id="submitFormButton"
                                    variant="contained" className={`${classes.themeButton} w-100 py-2 br-2 transform-initial`}>
                                    Login
                                </Button>
                                {/* { payload.showLoader && <CircularProgress size={24} className={classes.buttonProgress} />} */}
                            </div>
                        </form>
                        <div className="text-center">
                            <NavLink className={classes.defaultNavLink} to={`/forgot-password`}>
                                <Button className={`${classes.themeButton} ${classes.lightThemeButton} my-2 color-1-imp transform-initial fsize-16`}>
                                    Forgot Password?
                                </Button>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="bottom-block">
                    <div className="d-flex align-items-center w-100 justify-content-center">
                        <span className="fsize-16 color-21">Don't have an account?</span>
                        <div className="text-center">
                            <NavLink className={classes.defaultNavLink} to={`/register`}>
                                <Button className={`${classes.themeButton} ${classes.lightThemeButton} ${classes.noPadding} my-2 color-1-imp toLower`}>
                                    <span className="px-2">Signup Here</span>
                            </Button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginComponent