import React, { useCallback, useEffect, useState } from 'react'
import Slider from '@material-ui/core/Slider'
import Cropper from 'react-easy-crop'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import getCroppedImage from './CropImage'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
    },
    sliderLabel: {
        [theme.breakpoints.down('xs')]: {
            minWidth: 65,
        },
    },
    slider: {
        padding: '22px 0px',
        marginLeft: 16,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0 16px',
        },
    }
}));

function ImageCropComponent({
    payload,
    open,
    handleOpen,
    setCroppedImage,
    ...rest
}) {
    const classes = useStyles();
    const [image, setImage] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    useEffect(() => {
        if(payload.fields.companyLogoImage) {
            setImage(payload.fields.companyLogoImage.blob)
        }
    }, [payload])

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImage(
                image,
                croppedAreaPixels,
                0
            )
            setCroppedImage(croppedImage)
            handleOpen()
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels])

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            className={classes.modal}
            BackdropProps={{
                timeout: 500,
            }}
            open={open}
            onClose={handleOpen}
        >
            {/* <Fade in={false}> */}
            <div className={"easyCropImage"}>
                <div className="crop-container">
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={4 / 4}
                        onCropChange={setCrop}
                        // onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
                <div className="controls">
                    <div className="controls-inner">
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e, zoom) => setZoom(zoom)}
                            // classes={{ container: classes.slider }}
                        />
                        <button onClick={showCroppedImage} className="btn controls-inner_btn">Done</button>
                    </div>
                </div>
            {/* </Fade> */}
            </div>
        </Modal>
    )
}

export default ImageCropComponent