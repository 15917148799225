export const SET_RECORDS="SET_RECORDS"
export const RESET_RECORDS="RESET_RECORDS"

export const SHOW_ALERT = "SHOW_ALERT"
export const HIDE_ALERT = "HIDE_ALERT"

export const SET_AUTH_SESSION = "SET_AUTH_SESSION"
export const HARD_REFRESH = "REFRESH"

export const LOADING = "LOADING"
export const LOADED = "LOADED"

export const SET_TOOLS = "SET_TOOLS"
export const RESET_MODAL = "CLOSE_MODAL"

export const STORE_SESSION = "STORE_SESSION"
export const LOGOUT = "LOGOUT"
export const AUTH_FAILURE = "AUTH_FAILURE"
export const RESET_AUTH_SESSION = "RESET_AUTH_SESSION"