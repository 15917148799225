import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import { CONSTANTS } from '../../../../_utils/_constants';
import { triggerModal } from '../../../../_actions/common';
import { connect } from 'react-redux';
import _ from 'lodash'
import { capitalize } from '../../../../_utils/_handlers';

function EmployeeTable({
    triggerModal,
    row,
    ageRangeList,
    ...props
}){
    return (
        <TableRow
            hover
            tabIndex={-1}
            key={row.id}
        >
            <TableCell align="left" className="max-w25">
                <div className="d-flex flex-row py-0 align-items-center">
                    <div className="fsize-16 fw-500">{row.user && row.user.name ? row.user.name : ""}</div>
                </div>
            </TableCell>
            <TableCell align="left" className="max-w20">
                <div className="d-flex flex-row py-0 align-items-center">
                    {/* <div className="fsize-16 fw-500">{!_.isEmpty(ageRangeList) && row.user && ageRangeList[row.user.ageRangeCode] ? ageRangeList[row.user.ageRangeCode].description : "NA"}</div> */}
                    <div className="fsize-16 fw-500">{capitalize(row.jobDesignationCode)}</div>
                </div>
            </TableCell>
            <TableCell align="left" className="max-w20">
                <div className="d-flex flex-row py-0 align-items-center">
                    <div className="fsize-16 fw-500">{row.user && row.user.phoneNumber ? row.user.phoneNumber : ""}</div>
                </div>
            </TableCell>
            <TableCell align="left" className="max-w40">
                <div className="d-flex">
                    <div className="mx-auto d-flex align-items-center">
                        <Button className="fw-500 text-underline toLower color-1-imp rmv-outline" onClick={e => triggerModal({
                            show: true,
                            hide: true,
                            type: CONSTANTS.routeType.EMPLOYEE,
                            subtype: CONSTANTS.operation.VIEW,
                            data: {...row, ...row.user},
                            matchLoad: {
                                params: {
                                    resourceId: row.storeId
                                }
                            }
                        })}>View Details</Button>
                        <Button className="fw-500 text-underline toLower text-danger rmv-outline" onClick={e => triggerModal({
                            show: true,
                            hide: true,
                            type: CONSTANTS.routeType.EMPLOYEE,
                            subtype: CONSTANTS.operation.REMOVE,
                            data: {...row, ...row.user},
                            size: "md",
                            matchLoad: {
                                params: {
                                    resourceId: row.storeId
                                }
                            }
                        })}>Remove Employee</Button>
                    </div>
                </div>
            </TableCell>
        </TableRow>
    )
}

const mapStateToProps = state => {
    return {
        ageRangeList: state.records && state.records.constants ? state.records.constants.userAgeGroupList : {}
    }
}

export default connect(mapStateToProps, { triggerModal })(EmployeeTable)