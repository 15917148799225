import React, { useEffect, useState } from 'react'
import OrganisationHead from '../reusable/_organisationHead'
import _ from 'lodash'
import { CONSTANTS } from '../../_utils/_constants';
import { ProfileTemplate, EditStoreTemplate } from '../modal/formTemplates';
import { handleInputChange } from '../../_utils/_handlers';
import { connect } from 'react-redux';
import { fetchRecords, postRecords } from '../../_actions/common';
import Geocode from "react-geocode";
import { validatePhoneNumber, validateURL } from '../../_utils/_validators';

Geocode.setApiKey(process.env.REACT_APP_GMAP_KEY);
// Geocode.setApiKey("AIzaSyBvD73khNYpYFjm8RA5b_iKfZO8RPYJpyA");
Geocode.setLanguage("en");
Geocode.setRegion("in");
Geocode.enableDebug();

function FormIndex({
    match,
    editMode,
    fetchRecords,
    organisationDetails,
    listLocations,
    postRecords,
    ...rest
}) {
    const [routeType, setRouteType] = useState('')
    const [payload, setPayload] = useState({})
    const [disabled, setDisabled] = useState(true)
    const [loading, setLoading] = useState(-1)
    const [errorLoad, setErrorLoad] = useState({})

    const inputHandler = (e, d = '') => {
        if (d === "MuiSelect" && (_.isNull(e.target.value) || _.isUndefined(e.target.value))) {
            let p = _.omit(payload, [e.target.name])
            setPayload({
                ...p
            })
            return
        }
        handleInputChange(e, cb => {
            setPayload({
                ...payload,
                [cb.name]: cb.value
            })
        })
    }

    const submitForm = (e, type = -1, callback) => {
        e.preventDefault();
        setLoading(type)
        document.querySelectorAll("button").forEach(e => e.setAttribute("disabled", "disabled"))

        let customPayload = {
            routeType: match.params.routeType
        }
        
        let errorObj = {}
        if(match.params.routeType === CONSTANTS.routeType.PROFILE) {
            customPayload.operationType = type
            if(type === 0) {
                let { contactPhoneNumber, url, companyDescription, companyName } = payload
                if(contactPhoneNumber) {
                    errorObj = {...errorObj, ...validatePhoneNumber(contactPhoneNumber)}
                }
                if(url) {
                    errorObj = {...errorObj, ...validateURL(url)}
                }
                customPayload = {...customPayload, contactPhoneNumber: `+1${contactPhoneNumber}`, url, companyDescription, companyName}
            }
            else if(type === 1) {
                let { account_number, routing_number, account_holder_name, account_holder_type } = payload
                customPayload = {...customPayload, account_number, routing_number, account_holder_name, account_holder_type}
            }
            else if(type === 2) {
                let { taxIdentificationCode } = payload
                customPayload = {...customPayload, taxIdentificationCode}
            }
            else if(type === 3) {
                let { officeAndBuildingNumber, streetOrLocalityName, city, locationCode, zipCode, state } = payload
                let locationCodeNew = locationCode
                if(state) {
                    locationCodeNew = _.find(listLocations, { state }) ? _.find(listLocations, { state })['code'] : ""
                }
                customPayload = {...customPayload, officeAndBuildingNumber, streetOrLocalityName, city, locationCode: locationCodeNew, zipCode}
            }
        } 

        if(match.params.routeType === CONSTANTS.routeType.STORE) {
            customPayload.operationType = type
            customPayload.resourceId = match.params.resourceId
            if(type === 0) {
                let { storeName, storeIdentificationCode, contactPhoneNumber, url } = payload
                if(contactPhoneNumber) {
                    errorObj = {...errorObj, ...validatePhoneNumber(contactPhoneNumber)}
                }
                if(url) {
                    errorObj = {...errorObj, ...validateURL(url)}
                }
                customPayload = {...customPayload, storeName, storeIdentificationCode, contactPhoneNumber: `+1${contactPhoneNumber}`, url}
            }
            else if(type === 1) {
                let { account_number, routing_number, account_holder_name, account_holder_type } = payload
                customPayload = {...customPayload, account_number, routing_number, account_holder_name, account_holder_type}
            }
            else if(type === 2) {
                let { taxIdentificationCode, companyName, companyDescription } = payload
                customPayload = {...customPayload, taxIdentificationCode, companyName, companyDescription}
            }
            else if(type === 3) {
                let { officeAndBuildingNumber, streetOrLocalityName, city, locationCode, zipCode, state } = payload
                let locationCodeNew = locationCode
                if(state) {
                    locationCodeNew = _.find(listLocations, { state }) ? _.find(listLocations, { state })['code'] : ""
                }
                customPayload = { ...customPayload, officeAndBuildingNumber, streetOrLocalityName, city, locationCode: locationCodeNew, zipCode }
                customPayload = {
                    ...customPayload,
                    locationCoordinates: {
                        "type": "Point",
                        "coordinates": payload.locationCoordinates.coordinates
                    }
                }
            }
        }

        // Error Handling
        if(!_.isEmpty(errorObj)) {
            setErrorLoad({...errorObj})
            setLoading(-1)
            document.querySelectorAll("button").forEach(e => e.removeAttribute("disabled"))
            return
        }
        else {
            setErrorLoad({})
        }

        postRecords(customPayload.routeType, {...customPayload}, cb => {
            setLoading(-1)
            document.querySelectorAll("button").forEach(e => e.removeAttribute("disabled"))
            callback(cb)
        }, false)
    }

    useEffect(() => {
        setRouteType(match.params.routeType || CONSTANTS.routeType.HOME)
        if(match.params.routeType !== CONSTANTS.routeType.PROFILE) {
            let additionalParams = {}
            if(match.params.routeType === CONSTANTS.routeType.STORE) {
                additionalParams.operationType = 'get'
            }
            fetchRecords({
                ...match.params,
                ...additionalParams
            }, callback => {
                if(callback.hasError) {
                    return
                }
                if(callback.data) {
                    let details = callback.data
                    let paymentDetails = {}
                    if(details.stripeConnectAccountRequirementsAndBankDetails && details.stripeConnectAccountRequirementsAndBankDetails.stripeConnectAccountDefaultBankAccount) {
                        paymentDetails = {...details.stripeConnectAccountRequirementsAndBankDetails.stripeConnectAccountDefaultBankAccount}
                    }
                    setPayload({
                        ...details,
                        ...paymentDetails,
                        account_number: `********${paymentDetails.account_number_last4}`,
                        taxIdentificationCode: `*********`,
                        state: _.find(listLocations, { code: details.locationCode }) ? _.find(listLocations, { code: details.locationCode })['state'] : "",
                        contactPhoneNumber: organisationDetails.contactPhoneNumber ? organisationDetails.contactPhoneNumber.replace("+1", "") : ""
                    })
                }
            })
        }
        else if(match.params.routeType === CONSTANTS.routeType.PROFILE) {
            let paymentDetails = {}
            if(organisationDetails.stripeConnectAccountDefaultBankAccount) {
                paymentDetails = {...organisationDetails.stripeConnectAccountDefaultBankAccount}
            }
            setPayload({
                ...organisationDetails,
                ...paymentDetails,
                account_number: `********${paymentDetails.account_number_last4}`,
                taxIdentificationCode: `*********`,
                state: _.find(listLocations, { code: organisationDetails.locationCode }) ? _.find(listLocations, { code: organisationDetails.locationCode })['state'] : "",
                contactPhoneNumber: organisationDetails.contactPhoneNumber ? organisationDetails.contactPhoneNumber.replace("+1", "") : ""
            })
        }
    }, [match.params.routeType, organisationDetails])

    useEffect(() => {
        if(payload.locationSearch && payload.locationSearch.place_id && payload.locationSearch.description) {
            Geocode.fromAddress(payload.locationSearch.description).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    setPayload({
                        ...payload,
                        locationCoordinates: {
                            coordinates: [lng, lat]
                        }
                    })
                },
                error => {
                    console.error(error);
                }
            );
        }
    }, [payload.locationSearch])

    useEffect(() => {
        if(editMode) {
            setDisabled(false)
        }
    }, [editMode])

    return (
        <React.Fragment>
            <div className="organisation--container container">
                <OrganisationHead 
                routeType={routeType}
                />
                {
                    routeType === CONSTANTS.routeType.PROFILE && 
                    <ProfileTemplate
                    inputHandler={inputHandler}
                    payload={payload}
                    loading={loading}
                    setPayload={setPayload}
                    submitForm={submitForm}
                    errorLoad={errorLoad}
                    />
                }
                {
                    routeType === CONSTANTS.routeType.STORE && 
                    <EditStoreTemplate
                    inputHandler={inputHandler}
                    payload={payload}
                    loading={loading}
                    storeType={payload.isVirtualStore === undefined ? "" : (!payload.isVirtualStore ? "physical" : "online")}
                    setPayload={setPayload}
                    submitForm={submitForm}
                    errorLoad={errorLoad}
                    />
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        editMode: state.tools.editMode || false,
        organisationDetails: state.auth && state.auth.user ? state.auth.user : {},
        listLocations: state.records && state.records.constants && state.records.constants.listLocations ? state.records.constants.listLocations : []
    }
}

export default connect(mapStateToProps, { fetchRecords, postRecords })(FormIndex)