import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { connect } from 'react-redux';
import { postRecords, triggerModal } from '../../_actions/common';
import Button from '@material-ui/core/Button';
import { HomePageStyles } from '../../_styles/mui';
import { handleInputChange } from '../../_utils/_handlers';
import _ from 'lodash'
import { EmployeeTemplate, StoreTemplate, TransactionTemplate } from './formTemplates';
import { CONSTANTS } from '../../_utils/_constants';
import { globalValidator } from '../../_utils/_validators';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const indexStyles = theme => HomePageStyles(theme)

function ModelIndex({
    triggerModal,
    reduxModal,
    postRecords,
    s3BaseUrl,
    match,
    ...rest
}) {
    const classes = useStyles();
    const indexClass = indexStyles();
    const [payload, setPayload] = useState({})
    const [heading, setHeading] = useState("")
    const [errorHandler, setErrorHandler] = useState([])
    const [disabled, setDisabled] = useState(false)
    const [stepDetails, setStepDetails] = useState({ multistep: false, step: 1, totalSteps: 1 })

    const inputHandler = (e, d = {}) => {
        if (d === "MuiSelect" && (_.isNull(e.target.value) || _.isUndefined(e.target.value))) {
            let p = _.omit(payload, [e.target.name])
            setPayload({
                ...p
            })
            return
        }
        let otherFields = {}
        handleInputChange(e, cb => {
            // if(cb.name === "selectedEmployee") {
            //     otherFields['protoData'] = e.target.protoData
            // }
            setPayload({
                ...payload,
                [cb.name]: cb.value,
                ...otherFields
            })
        })
    }

    const submitFormHandler = e => {
        e.preventDefault();
        if(stepDetails.multistep && stepDetails.step < stepDetails.totalSteps) {
            setStepDetails({
                ...stepDetails,
                step: stepDetails.step + 1
            })
            return
        }
        if(reduxModal.type === CONSTANTS.routeType.EMPLOYEE && reduxModal.subtype === CONSTANTS.operation.ROADBLOCK) {
            triggerModalHandler({
                subtype: CONSTANTS.operation.ADD,
                size: "lg",
                data: {...payload.selectedEmployee}
            })
            return
        }
        let infoLoad = {
            routeType: reduxModal.type,
            operationType: reduxModal.subtype
        }
        if(reduxModal.type === CONSTANTS.routeType.EMPLOYEE) {
            infoLoad.resourceId = reduxModal.matchLoad && reduxModal.matchLoad.params && reduxModal.matchLoad.params.resourceId ? reduxModal.matchLoad.params.resourceId : ""
        }
        postRecords(reduxModal.type, {...payload, ...infoLoad}, callback => {
            console.log(`Submit FormHandler:`, callback)
            if(callback.hasError) {
                return;
            }
            triggerModal({ show: false })
        })
    }

    const triggerModalHandler = (options = {}) => {
        let validator = globalValidator(reduxModal.type, reduxModal.subtype, {...payload})
        if (validator.error) {
            setErrorHandler({ ...validator })
        }
        else {
            triggerModal({
                ...reduxModal,
                ...options
            })
        }
    }

    const ModalContent = (modalStep = 1) => {
        switch(reduxModal.type) {
            case CONSTANTS.routeType.EMPLOYEE: {
                let head = [CONSTANTS.operation.ADD, CONSTANTS.operation.ROADBLOCK].indexOf(reduxModal.subtype) > -1 ? "Add an Employee" : 
                    (CONSTANTS.operation.REMOVE === reduxModal.subtype ? "Remove Employee" : "Employee Details")
                setHeading(head)
                break;
            }
            case CONSTANTS.routeType.TRANSACTION: {
                let head = 'Transaction Details'
                setHeading(head)
                break;
            }
            default: return;
        }
    }

    useEffect(() => {
        // let unmounted = false
        setDisabled(reduxModal.subtype !== CONSTANTS.operation.VIEW ? false : true)
        setHeading()
        setPayload({})
        setErrorHandler({})
        ModalContent()
        setPayload({ ...reduxModal.data })
        if(reduxModal.type === CONSTANTS.routeType.ONSTORE) {
            setStepDetails({ multistep: true, step: 1, totalSteps: 2 })
        }
        else {
            setStepDetails({ multistep: false, step: 1, totalSteps: 1 })
        }
        // API Calls

        // return () => { unmounted = true };
    }, [reduxModal.type, reduxModal.subtype])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={reduxModal.show || false}
                onClose={e => triggerModal({
                    show: false
                })}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
            {
                reduxModal.show ?
                <Fade in={reduxModal.show || false}>
                    <div className={`${classes.paper} modal--wrapper`} data-size={reduxModal.size || "lg"}>
                        <div className="d-flex flex-column w-100">
                            <div className="bb-2 p-4">
                                <h2 className="mb-0 mx-2 fsize-28">{heading}</h2>
                            </div>
                            <div className="form-wrap">
                                <form onSubmit={submitFormHandler}>
                                    {
                                        reduxModal.type === CONSTANTS.routeType.EMPLOYEE && 
                                        <EmployeeTemplate 
                                            s3BaseUrl={s3BaseUrl}
                                            operation={reduxModal.subtype} 
                                            inputHandler={inputHandler} 
                                            payload={payload}
                                            disabled={disabled}
                                        />
                                    }
                                    {
                                        reduxModal.type === CONSTANTS.routeType.ONSTORE && 
                                        <StoreTemplate 
                                            // operation={reduxModal.subtype} 
                                            inputHandler={inputHandler} 
                                            setHeading={setHeading}
                                            payload={payload}
                                            disabled={disabled}
                                            step={stepDetails.step}
                                        />
                                    }
                                    {
                                        reduxModal.type === CONSTANTS.routeType.TRANSACTION && 
                                        <TransactionTemplate
                                        payload={payload}
                                        />
                                    }
                                    {
                                        reduxModal.type !== CONSTANTS.routeType.TRANSACTION &&
                                        <div className="form-wrap_row mt-4 d-flex flex-row justify-content-between">
                                            <div className="d-flex align-items-center">
                                                {
                                                    stepDetails.multistep && 
                                                    <React.Fragment>
                                                        <IconButton onClick={e => setStepDetails({
                                                            ...stepDetails,
                                                            step: stepDetails.step - 1 
                                                        })} aria-label="delete" 
                                                        disabled={stepDetails.step === 1}
                                                        className={`${classes.margin} rmv-outline`} size="small">
                                                            <ArrowBackIos fontSize="inherit" />
                                                        </IconButton>
                                                        <span>{stepDetails.step} of {stepDetails.totalSteps}</span>
                                                        <IconButton type="submit" aria-label="delete" 
                                                        disabled={stepDetails.step === stepDetails.totalSteps}
                                                        className={`${classes.margin} rmv-outline`} size="small">
                                                            <ArrowForwardIos fontSize="inherit" />
                                                        </IconButton>
                                                    </React.Fragment>
                                                }
                                            </div>
                                            <div className="d-flex justify-content-end flex-row-reverse btn-wrap">
                                                {
                                                    !([CONSTANTS.operation.ROADBLOCK].indexOf(reduxModal.subtype) > -1) ? (
                                                        ([CONSTANTS.operation.VIEW].indexOf(reduxModal.subtype) > -1) ?
                                                            <Button variant="contained"
                                                                onClick={e => { e.preventDefault(); triggerModal({ ...reduxModal, subtype: CONSTANTS.operation.EDIT }) }}
                                                                type={"button"}
                                                                className={`${indexClass.themeButton} d-flex align-items-center toLower stripBtn`}
                                                            >
                                                                Edit
                                                            </Button> :
                                                            <Button variant="contained"
                                                                type={[CONSTANTS.operation.ADD, CONSTANTS.operation.EDIT, CONSTANTS.operation.REMOVE].indexOf(reduxModal.subtype) > -1 ? "submit" : "button"}
                                                                className={`${indexClass.themeButton} d-flex align-items-center toLower stripBtn`}
                                                            >
                                                                {(stepDetails.multistep === true && stepDetails.totalSteps > stepDetails.step) ? "Next" :
                                                                    ([CONSTANTS.operation.REMOVE].indexOf(reduxModal.subtype) > -1 ? "Remove" : ([CONSTANTS.operation.VIEW].indexOf(reduxModal.subtype) > -1 ? "Edit" : "Save"))}
                                                            </Button>) : <React.Fragment>
                                                            <Button
                                                                variant="contained"
                                                                onClick={e => triggerModalHandler({
                                                                    subtype: CONSTANTS.operation.ADD,
                                                                    size: "lg",
                                                                    data: {...payload.selectedEmployee}
                                                                })}
                                                                type={"button"}
                                                                className={`${indexClass.themeButton} d-flex align-items-center toLower stripBtn`}
                                                            >
                                                                Add Employee
                                                            </Button>
                                                        </React.Fragment>
                                                }
                                                <Button variant="contained"
                                                    onClick={e => { e.preventDefault(); triggerModal({
                                                        show: false
                                                    }) }}
                                                    type={"button"}
                                                    className={`${indexClass.themeButton} ${indexClass.lightThemeButton} d-flex align-items-center mx-2 toLower stripBtn`}
                                                >
                                                    {[CONSTANTS.operation.REMOVE].indexOf(reduxModal.subtype) > -1 ? "Cancel" : "Close"}
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                    {
                                        reduxModal.type === CONSTANTS.routeType.TRANSACTION && 
                                        <div className="form-wrap_row mt-4 d-flex flex-row">
                                            <div className="btn-wrap mx-auto">
                                                <Button variant="contained" 
                                                onClick={e => { e.preventDefault(); triggerModal({
                                                    show: false
                                                }) }}
                                                style={{padding: "20px 80px !important"}} 
                                                className={`${indexClass.themeButton} ${indexClass['themeButton-ep']}`}>
                                                    Close
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                    {errorHandler.error && <div className="formProcess text-danger">* {errorHandler.message}</div>}
                                </form>
                            </div>
                        </div>
                    </div>
                </Fade> : <div></div>
            }
            </Modal>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        reduxModal: state.tools || {},
        s3BaseUrl: state.records && state.records.constants && state.records.constants.s3BaseUrl ? state.records.constants.s3BaseUrl : ""
    }
}

export default connect(mapStateToProps, { triggerModal, postRecords })(ModelIndex)