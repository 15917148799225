import React, { useEffect, useState } from 'react'
// import { withGoogleMap, GoogleMap, withScriptjs, InfoWindow, Marker } from "react-google-maps";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function GoogleMapComponent({ coordinates, height = '300px', zoom = 12, placeName, className = "mt-4"}) {
    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return (
        // <LoadScript
        //     id="google-maps-view"
        //     googleMapsApiKey="AIzaSyBvD73khNYpYFjm8RA5b_iKfZO8RPYJpyA"
        // >
            <GoogleMap
                id="google-maps-view-child"
                mapContainerStyle={{ width:"100%", height }}
                center={{...coordinates}}
                zoom={zoom}
                draggable={false}
                // onLoad={onLoad}
                // onUnmount={onUnmount}
            >
                { coordinates.lat !== 39.8868751 && 
                    <Marker
                        name={placeName}
                        draggable={false}
                        position={{...coordinates}}
                    />
                }
            </GoogleMap>
        // </LoadScript>
    )
}

// function GoogleMapComponent({ coordinates, height = '300px', zoom = 12, placeName, className = "mt-4" }) {
//     const [map, createMap] = useState(null)

//     useEffect(() => {
//         if (coordinates && coordinates.lat && coordinates.lng) {
//             createMap(<div className={className}>
//                 <AsyncMap
//                     googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBvD73khNYpYFjm8RA5b_iKfZO8RPYJpyA&libraries=places"
//                     loadingElement={
//                         <div style={{ height: `100%` }} />
//                     }
//                     containerElement={
//                         <div style={{ height: height }} />
//                     }
//                     mapElement={
//                         <div style={{ height: `100%` }} />
//                     }
//                 />
//             </div>)
//         }
//     }, [coordinates])

//     const AsyncMap = withScriptjs(
//         withGoogleMap(
//             () => (
//                 <GoogleMap
//                     defaultZoom={zoom}
//                     defaultCenter={{...coordinates}}
//                 >
//                     <Marker
//                         name={placeName}
//                         draggable={false}
//                         position={{...coordinates}}
//                     />
//                     <Marker />
//                     {/* InfoWindow on top of marker */}
//                     {/* <InfoWindow
//                         position={{ lat: (coordinates.lat + 0.0030), lng: coordinates.lng }}
//                     >
//                         <div>
//                             <span style={{ padding: 0, margin: 0 }}>{placeName}</span>
//                         </div>
//                     </InfoWindow> */}
//                 </GoogleMap>
//             )
//         )
//     );

//     return (
//         <React.Fragment>
//             <div className={className}>
//                 {map}
//             </div>
//         </React.Fragment>
//     )
// }

export default GoogleMapComponent