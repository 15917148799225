import React, { useEffect, useState } from 'react'
import OrganisationHead from '../../reusable/_organisationHead'
import _ from 'lodash'
import { CONSTANTS } from '../../../_utils/_constants';
import { PhysicalStoreAddTemplate } from '../../modal/formTemplates';
import { capitalize, handleInputChange } from '../../../_utils/_handlers';
import Button from '@material-ui/core/Button';
import { HomePageStyles } from '../../../_styles/mui';
import Geocode from "react-geocode";
import HorizontalStepper from '../../shared/hstepper';
import { connect } from 'react-redux';
import { createEditStore } from '../../../_actions/common';
import { validatePhoneNumber, validateURL } from '../../../_utils/_validators';

Geocode.setApiKey(process.env.REACT_APP_GMAP_KEY);
Geocode.setLanguage("en");
Geocode.setRegion("in");
Geocode.enableDebug();

const indexStyles = theme => HomePageStyles(theme)

function AddStore({
    match,
    history,
    organisationDetails,
    createEditStore,
    ...rest
}) {
    const indexClass = indexStyles();
    const [payload, setPayload] = useState({})
    const [errorLoad, setErrorLoad] = useState({})
    const [stepDetails, setStepDetails] = useState({ multistep: false, step: 1, totalSteps: 4 })

    useEffect(() => {
        if(payload.isAutofillCompanyDetailsFromParentOrganisation) {
            setPayload({
                ...payload,
                companyName: organisationDetails.companyName,
                companyDescription: organisationDetails.companyDescription,
                taxIdentificationCode: "*********"
            })
        }
        else {
            setPayload({
                ...payload,
                companyName: '',
                companyDescription: '',
                taxIdentificationCode: ''
            })
        }
    }, [payload.isAutofillCompanyDetailsFromParentOrganisation])

    useEffect(() => {
        if(payload.paymentDetailsSameAsOrganisation) {
            setPayload({
                ...payload,
                account_holder_name: organisationDetails.stripeConnectAccountDefaultBankAccount && organisationDetails.stripeConnectAccountDefaultBankAccount.account_holder_name,
                routing_number: organisationDetails.stripeConnectAccountDefaultBankAccount && organisationDetails.stripeConnectAccountDefaultBankAccount.routing_number,
                confirm_routing_number: organisationDetails.stripeConnectAccountDefaultBankAccount && organisationDetails.stripeConnectAccountDefaultBankAccount.routing_number,
                account_holder_type: organisationDetails.stripeConnectAccountDefaultBankAccount && organisationDetails.stripeConnectAccountDefaultBankAccount.account_holder_type
            })
        }
        else {
            setPayload({
                ...payload,
                account_holder_name: '',
                routing_number: '',
                confirm_routing_number: '',
                account_holder_type: ''
            })
        }
    }, [payload.paymentDetailsSameAsOrganisation])

    const inputHandler = (e, d = '') => {
        if (d === "MuiSelect" && (_.isNull(e.target.value) || _.isUndefined(e.target.value))) {
            let p = _.omit(payload, [e.target.name])
            setPayload({
                ...p
            })
            return
        }
        handleInputChange(e, cb => {
            setPayload({
                ...payload,
                [cb.name]: cb.value
            })
        })
    }

    const extractAddressFromArray = (addressArray = []) => {
        let obj = {
            officeAndBuildingNumber: "",
            streetOrLocalityName: "",
            city: "",
            state: "",
            zipCode: ""  
        };
        _.map(addressArray, o => {
            if(o.types && _.isArray(o.types)) {
                _.map(o.types, e => {
                    if(e === "street_number") {
                        obj.officeAndBuildingNumber = o.long_name
                    }
                    if(e === "route") {
                        obj.streetOrLocalityName = o.long_name
                    }
                    if(e === "locality") {
                        obj.city = o.long_name
                    }
                    if(e === "administrative_area_level_1") {
                        obj.state = o.long_name
                    }
                    if(e === "postal_code") {
                        obj.zipCode = o.long_name
                    }
                })
            }
        })
        return obj
    }

    useEffect(() => {
        if(payload.locationSearch && payload.locationSearch.place_id && payload.locationSearch.description) {
            Geocode.fromAddress(payload.locationSearch.description).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    console.log("Geocoding Response containing Lat and Lng: ", response)
                    console.log("Autocomplete Response: ", payload.locationSearch)
                    setPayload({
                        ...payload,
                        ...extractAddressFromArray(response.results[0].address_components),
                        locationCoordinates: {
                            coordinates: [lng, lat]
                        }
                    })
                },
                error => {
                    console.log("Here at error: ", error.response)
                    console.error(error);
                }
            );
        }
    }, [payload.locationSearch])

    const submitForm = e => {
        e.preventDefault();
        let err = {}
        if(payload.contactPhoneNumber) {
            let c = validatePhoneNumber(payload.contactPhoneNumber)
            err = {...err, ...c}
        }
        if(payload.url) {
            let c = validateURL(payload.url)
            err = {...err, ...c}
        }
        if(stepDetails.step === stepDetails.totalSteps) {
            if(payload.account_number && payload.account_number !== payload.confirm_account_number) {
                err.confirm_account_number = "Account number(s) aren't same"
                err.account_number = ""
            }
            if(payload.routing_number && payload.routing_number !== payload.confirm_routing_number) {
                err.confirm_routing_number = "Routing number aren't same"
                err.routing_number = ""
            }
        }
        
        if(!_.isEmpty(err)) {
            setErrorLoad({
                ...err
            })
            return
        }
        else {
            setErrorLoad({})
        }

        if(stepDetails.step < stepDetails.totalSteps) {
            setStepDetails({
                ...stepDetails,
                step: stepDetails.step + 1
            })
            return;
        }

        setErrorLoad({})
        let formLoad = {
            isVirtualStore: match.params.storeType === "physical" ? false : true,
            isAutofillCompanyDetailsFromParentOrganisation: payload.isAutofillCompanyDetailsFromParentOrganisation || false,
            contactPhoneNumber: `+1${payload.contactPhoneNumber}`
        }
        createEditStore({...payload, ...formLoad}, callback => {
            if(!callback.hasError) {
                history.push('/dashboard')
            }
        })
    }

    const [getSteps, setSteps] = useState(['Basic Information', 'Location Details', 'Company Details', 'Bank Details'])

    useEffect(() => {
        if(match.params.storeType === "online") {
            setSteps(['Basic Information', 'Company Details', 'Bank Details'])
            setStepDetails({ multistep: false, step: 1, totalSteps: 3 })
        }
    }, [])

    return (
        <React.Fragment>
            <div className="organisation--container container">
                <form onSubmit={submitForm} autoComplete={"off"}>
                    <OrganisationHead 
                    routeType={CONSTANTS.routeType.PYSTORE}
                    operationType={match.params.operationType || ""}
                    />
                    <h6 className="mt-0 mb-3 pb-3 color-sm fw-500 fsize-16 ml-md-5 pl-md-3">
                        { stepDetails.step === 1 && 'Fill in the required store details'}
                        { ((stepDetails.step === 3 && match.params.storeType === "physical") || (stepDetails.step === 2 && match.params.storeType === "online")) && 'Fill in the required Company Details'}
                        { stepDetails.step === 2 && match.params.storeType === "physical" && 'Fill in the Location Details below'}
                        { ((stepDetails.step === 4 && match.params.storeType === "physical") || (stepDetails.step === 3 && match.params.storeType === "online")) && 'Fill in the required details for payments'}
                    </h6>
                    <HorizontalStepper
                    getSteps={getSteps}
                    stepNumber={stepDetails.step}
                    />
                    <PhysicalStoreAddTemplate
                    inputHandler={inputHandler}
                    organisationDetails={organisationDetails}
                    payload={payload}
                    errorLoad={errorLoad}
                    storeType={match.params.storeType || ""}
                    step={stepDetails.step}
                    />
                    <div className="form-wrap_row mt-4 d-flex flex-row">
                        <div className="d-flex justify-content-end btn-wrap mr-auto mt-4">
                            <Button variant="contained"
                                type={"submit"}
                                className={`${indexClass.themeButton} ${indexClass['themeButton-ep']} toLower`}
                            >
                                { stepDetails.step === stepDetails.totalSteps ? 'Add Store' : 'Next'}
                            </Button> 
                            {
                                stepDetails.step > 1 &&
                                <Button variant="contained"
                                    type={"button"}
                                    onClick={e => setStepDetails({...stepDetails, step: stepDetails.step - 1})}
                                    className={`${indexClass.lightThemeButton} ${indexClass['themeButton-ep']} rmv-outline toLower mx-2`}
                                >
                                    Previous
                                </Button> 
                            }
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        organisationDetails: state.auth && state.auth.user ? state.auth.user : {}
    }
}

export default connect(mapStateToProps, { createEditStore })(AddStore)