import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import AuthContainer from '../container/auth';
import IndexContainer from '../container/home';
import OrganisationComponent from '../container/organisation';
import { loadConstants, loadUser } from '../_actions/auth';
import { CONSTANTS } from '../_utils/_constants';
import { queryParamsToObject } from '../_utils/_handlers';

const Router = ({
    authLevel,
    registerLevel,
    loadUser,
    loadConstants
}) => {
    const history = useHistory()
    const [redirectToReferrer, setRedirection] = useState(history.location.search)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadConstants()
        setLoading(true)
        loadUser(() => {
            setLoading(false)
        }, window.location.pathname == "/" ? false : true)
    }, [loadUser])

    useEffect(() => {
        if(authLevel && redirectToReferrer) {
            history.push(redirectToReferrer.to || "/dashboard")
        }
    }, [authLevel, redirectToReferrer])

    useEffect(() => {
        if(history.location.search) {
            let queryP = queryParamsToObject(history.location.search)
            setRedirection(queryP)
        }
    }, [history.location.search])

    return (
        <Switch>
            <Route path={`/dashboard`} component={OrganisationComponent} />
            { !authLevel && <Route path="/:routeType(login|forgot-password)" component={AuthContainer} />}
            { (!authLevel || registerLevel) && <Route path="/:routeType(register)" component={AuthContainer} />}
            <Route path="/" exact component={IndexContainer} />
            <Redirect to="/dashboard" from={`/(login|signup)`}/>
            <Redirect to="/" />
        </Switch>
    )
};

const mapStateToProps = state => {
    return {
        authLevel: state.auth.token && state.auth.step || false,
        registerLevel: (state.auth.token && state.auth.step < CONSTANTS.registration.FINAL) || false
    }
}

export default connect(mapStateToProps, { loadUser, loadConstants })(Router);