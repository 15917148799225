import { CONSTANTS } from "../_utils/_constants";

export default {
    table: {
        [CONSTANTS.routeType.HOME]: {
            columns : [
                { id: 'name', numeric: false, disablePadding: true, label: 'Store Name' },
                { id: 'storeId', numeric: false, disablePadding: true, label: 'Store ID' },
                { id: 'storeAddress', numeric: false, disablePadding: true, label: 'Store Address' },
                { id: 'actions', numeric: 1, disablePadding: true, label: 'Actions' }
            ]
        },
        [CONSTANTS.routeType.EMPLOYEE]: {
            columns : [
                { id: 'name', numeric: false, disablePadding: true, label: 'Employee Name' },
                { id: 'position', numeric: false, disablePadding: true, label: 'Designation' },
                { id: 'contact', numeric: false, disablePadding: true, label: 'Contact' },
                { id: 'actions', numeric: 1, disablePadding: true, label: 'Actions' }
            ]
        },
        [CONSTANTS.routeType.TRANSACTION]: {
            columns : [
                { id: 'id', numeric: false, disablePadding: true, label: 'Transaction Id' },
                { id: 'voucher_number', numeric: false, disablePadding: true, label: 'Voucher Number' },
                { id: 'cashier_name', numeric: false, disablePadding: true, label: 'Cashier Name' },
                { id: 'date', numeric: false, disablePadding: true, label: 'Date' },
                { id: 'amount', numeric: false, disablePadding: true, label: 'Amount' },
                // { id: 'actions', numeric: 1, disablePadding: true, label: 'Actions' }
            ]
        }
    }
}