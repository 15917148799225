import querystring from 'query-string'

export function handleInputChange(e,cb){
    const target = e.target;
    let value = target.value;
    const name = target.name;

    if (e.target.files) {
        let { files } = e.target;
        value = {
            blob : '',
            binary: '',
            url: ''
        }
        value.blob = window.URL.createObjectURL(files[0]);
        value.binary = files[0]
    }

    let payload = {
        name,
        value,
        type : e.target.type
    }
    if(e.target.type === "checkbox"){
        payload.checked = e.target.checked
    }

    cb(payload)
}

export const capitalize = (string) => {
    if(string === undefined || string === null){
        return
    }
    string = string.charAt(0).toUpperCase() + string.slice(1);
    string = string.replace(/_/g," ")
    return string.toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');;
}

export const normalise = (string) => {
    if(string === undefined || string === null){
        return
    }
    return string.toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toLowerCase() + word.slice(1))
    .join('_');
}

export const hypenize = string => {
    if(string === undefined || string === null){
        return
    }
    return string.toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toLowerCase() + word.slice(1))
    .join('-'); 
}

export const queryParamsToObject = data => {
    return querystring.parse(data)
}

export const queryParamsToString = data => {
    return querystring.stringify(data)
}

export const convertOptions = params => {
    let a = Object.values(params).join("-")
    let b = Object.keys(params).join("-")
    return `${a}-${b}`
}

export const convertNormalOptions = params => {
    let a = Object.values(params).join("-")
    return `${a}`
}

export const checkIfNullOrUndefined = value => {
    if([undefined, null].indexOf(value) > -1) {
        return false
    }
    return true
}

export const sanitizeNumber = value => {
    if(isNaN(value)) {
        return -1;
    }
    return value * 1;
}

export const customStartWith = (value, startIndex) => {
    if(!value) return false;
    return (value + "").startsWith(startIndex + "")
}