import React, { useEffect, useRef, useState } from 'react'
import { HomePageStyles } from '../../_styles/mui';
import { handleInputChange } from '../../_utils/_handlers';
import LoginComponent from '../../components/auth/login';
import RegisterComponent from '../../components/auth/register';
import VerticalLinearStepper from '../../components/shared/vstepper'
import { userForgotPassword, userLogin, userSignup } from '../../_actions/auth';
import { connect } from 'react-redux';
import { CONSTANTS } from '../../_utils/_constants';
import ForgotPassword from '../../components/auth/forgot';
import _ from 'lodash'
import { validatePassword, validatePhoneNumber, validateURL } from '../../_utils/_validators';
import ImageCropComponent from '../../components/shared/ImageCrop';

const useStyles = (theme) => HomePageStyles(theme)

function AuthContainer({
    match,
    history,
    userLogin,
    userSignup,
    registerLevel,
    organisationData,
    companyDetails,
    userForgotPassword,
    authToken,
    ...rest
}) {
    const classes = useStyles();
    const formRef = useRef(null)
    const [payload, setPayload] = useState({
        error: {},
        fields: {}
    })
    const [classList, setClassList] = useState(false)
    const [open, setOpen] = useState(false)
    const [token, hasToken] = useState(false)

    const inputHandler = e => {
        handleInputChange(e, cb => {
            setPayload({
                ...payload,
                fields: {
                    ...payload.fields,
                    [cb.name]: cb.value
                }
            })
            if(cb.name === "companyLogoImage") {
                setOpen(true)
            }
        })
    }

    const submitForm = e => {
        e.preventDefault()
        document.querySelectorAll("input").forEach(e => e.setAttribute("disabled", "disabled"))
        document.querySelectorAll("button").forEach(e => e.setAttribute("disabled", "disabled"))
        let $el = document.getElementById("submitFormButton")
        $el.setAttribute("disabled", "disabled")
        setPayload({
            ...payload,
            showLoader: true
        })

        if(match.params.routeType === "register") {
            let errorObj = {}
            if(!registerLevel) {
                if(!_.isEmpty(payload.fields) && payload.fields.password) {
                    if(payload.fields.password != payload.fields['confirm-password']) {
                        errorObj['confirm-password'] = "Password didn't match"
                    }
                    else {
                        let c = validatePassword(payload.fields.password)
                        errorObj = {...errorObj, ...c}
                    }
                }
            }
            if(registerLevel === CONSTANTS.registration.EMAIL_VERIFIED) {
                if(!_.isEmpty(payload.fields) && payload.fields.url) {
                    let c = validateURL(payload.fields.url)
                    errorObj = {...errorObj, ...c}
                }
                if(!_.isEmpty(payload.fields) && payload.fields.contactPhoneNumber) {
                    let c = validatePhoneNumber(payload.fields.contactPhoneNumber)
                    errorObj = {...errorObj, ...c}
                }
                if(payload.fields && (!payload.fields.companyLogoImage || payload.fields.companyLogoImage && !payload.fields.companyLogoImage.blobObj)) {
                    errorObj['companyLogoImage'] = "Please upload company logo"
                }
            }
            if(registerLevel === CONSTANTS.registration.COMPANY_ADDRESS) {
                if(!_.isEmpty(payload.fields) && payload.fields.account_number && payload.fields.account_number != payload.fields['confirm_account_number']) {
                    errorObj['confirm_account_number'] = "Account number didn't match"
                }
                if(!_.isEmpty(payload.fields) && payload.fields.routing_number && payload.fields.routing_number != payload.fields['confirm_routing_number']) {
                    errorObj['confirm_routing_number'] = "Routing number didn't match"
                }
            }

            if(!_.isEmpty(errorObj)) {
                $el.removeAttribute("disabled")
                document.querySelectorAll("input").forEach(e => e.removeAttribute("disabled"))
                document.querySelectorAll("button").forEach(e => e.removeAttribute("disabled"))
                setPayload({
                    ...payload,
                    error: {
                        ...errorObj
                    },
                    showLoader: false
                })
                return
            }
            else {
                setPayload({...payload, error: {}, showLoader: false})
            }
            userSignup({...payload.fields}, cb => {
                $el.removeAttribute("disabled")
                document.querySelectorAll("input").forEach(e => e.removeAttribute("disabled"))
                document.querySelectorAll("button").forEach(e => e.removeAttribute("disabled"))
                setPayload({
                    ...payload,
                    error: {},
                    showLoader: false
                })
                if(!cb.hasError && cb.step === CONSTANTS.registration.FINAL && !history.location.search) {
                    history.push(`/dashboard`)
                }
            })
        }
        else if(match.params.routeType === "forgot-password") {
            if(payload.forgotStep) {
                if(!_.isEmpty(payload.fields) && payload.fields.password && payload.fields.password != payload.fields['confirm-password']) {
                    $el.removeAttribute("disabled")
                    document.querySelectorAll("input").forEach(e => e.removeAttribute("disabled"))
                    document.querySelectorAll("button").forEach(e => e.removeAttribute("disabled"))
                    setPayload({
                        ...payload,
                        error: {
                            ...payload.error,
                            'confirm-password': "Password didn't match"
                        },
                        showLoader: false
                    })
                    return
                }
                else {
                    setPayload({
                        ...payload,
                        error: {}
                    })
                }
            }
            userForgotPassword({...payload.fields}, cb => {
                $el.removeAttribute("disabled")
                document.querySelectorAll("input").forEach(e => e.removeAttribute("disabled"))
                document.querySelectorAll("button").forEach(e => e.removeAttribute("disabled"))
                let obj = {}
                let objChange = true
                if(cb.forgotStep) {
                    obj.forgotStep = cb.forgotStep
                    if(cb.forgotStep > 1) {
                        objChange = false
                        history.push(`/login`)
                        setPayload({
                            ...payload,
                            fields: {}
                        })
                    }
                }     
                if(objChange) {
                    setPayload({
                        ...payload,
                        showLoader: false,
                        ...obj
                    })
                }
            })
        }
        else {
            userLogin({...payload.fields}, cb => {
                $el.removeAttribute("disabled")
                document.querySelectorAll("input").forEach(e => e.removeAttribute("disabled"))
                document.querySelectorAll("button").forEach(e => e.removeAttribute("disabled"))
                setPayload({
                    ...payload,
                    showLoader: false
                })
            })
        }
    }

    useEffect(() => {
        if(registerLevel > CONSTANTS.registration.UNREGISTERED) {
            setClassList(true)
        }
        else {
            setClassList(false)
        }
    }, [registerLevel])

    useEffect(() => {
        setPayload({
            ...payload,
            fields: {
                ...payload.fields,
                password: "",
                'confirm-password': ""
            },
            error: {}
        })
    }, [match.params.routeType])

    useEffect(() => {
        setPayload({
            ...payload,
            fields: {
                ...payload.fields,
                ...companyDetails
            }
        })
    }, [companyDetails])

    useEffect(() => {
        hasToken(localStorage.getItem(process.env.REACT_APP_TOKEN))
        if(organisationData) {
            if(organisationData.registrationStatus === "UNREGISTERED") {
                setPayload({
                    ...payload,
                    fields: {
                        ...payload.fields,
                        email: organisationData.email
                    }
                })
            }
        }
    }, [organisationData])

    const handleOpen = () => {
        setOpen(open ? false : true)
    }

    const setCroppedImage = imageObj => {
        setPayload({
            ...payload,
            fields: {
                ...payload.fields,
                companyLogoImage: {
                    ...payload.fields.companyLogoImage,
                    originalImage: payload.fields.companyLogoImage.blob,
                    blob: imageObj.image,
                    blobObj: imageObj.file
                }
            }
        })
    }

    return (
        <React.Fragment>
            { 
                (!token && _.isEmpty(organisationData) || (authToken && organisationData.registrationStatus !== "REGISTERED")) ? <>
                <div className="container-fluid px-0">
                    <div className="d-flex auth--container">
                        <div className={`left ${classList ? 'animate' : ''}`}></div>
                        { classList && 
                            <div className="middle">
                                <div className="d-flex w-100 h-100 ml-md-5">
                                    <div className="m-auto">
                                        <VerticalLinearStepper step={registerLevel}/>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            match.params.routeType === "login" && 
                            <LoginComponent submitForm={submitForm}
                            inputHandler={inputHandler} 
                            payload={payload} formRef={formRef}
                            classes={classes}/>
                        }
                        {
                            match.params.routeType === "forgot-password" && 
                            <ForgotPassword submitForm={submitForm}
                            inputHandler={inputHandler} 
                            payload={payload} formRef={formRef}
                            classes={classes}/>
                        }
                        {
                            match.params.routeType === "register" && 
                            <RegisterComponent submitForm={submitForm}
                            inputHandler={inputHandler} 
                            stepper={classList}
                            orgData={organisationData}
                            payload={payload} formRef={formRef}
                            registerLevel={registerLevel}
                            classes={classes}/>
                        }
                    </div>
                </div>
                <ImageCropComponent
                payload={payload}
                open={open}
                setCroppedImage={setCroppedImage}
                handleOpen={handleOpen}
                />
                </> : ""
            }
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        authToken: state.auth.token || false,
        registerLevel: state.auth.step || false,
        organisationData: state.auth.user || {},
        companyDetails: state.auth.companyDetails || {}
    }
}

export default connect(mapStateToProps, { userLogin, userSignup, userForgotPassword })(AuthContainer)