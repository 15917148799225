import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import { RegistrationTemplate } from './../modal/formTemplates'
import { CONSTANTS } from '../../_utils/_constants';
import { logoutHandler } from '../../_actions/auth';
import { connect } from 'react-redux';

function RegisterComponent({
    inputHandler,
    formRef,
    submitForm,
    payload,
    classes,
    stepper,
    registerLevel,
    logoutHandler,
    orgData,
    ...rest
}) {
    const [heading, setHeading] = useState({
        h1: 'Create Account',
        h2: 'Register with your email and password'
    })

    useEffect(() => {
        if(!registerLevel) {
            setHeading({
                h1: 'Create Account',
                h2: 'Register with your email and password'
            })
        }
        else if(registerLevel === CONSTANTS.registration.UNREGISTERED) {
            setHeading({
                h1: 'Verify Email',
                h2: 'Enter OTP sent on your Email'
            })
        }
        else if(registerLevel === CONSTANTS.registration.EMAIL_VERIFIED) {
            setHeading({
                h1: 'Company Details',
                h2: 'We require details of your organization'
            })
        }
        else if(registerLevel === CONSTANTS.registration.COMPANY_DETAILS) {
            setHeading({
                h1: 'Company Address',
                h2: 'Add Address of your organization'
            })
        }
        else if(registerLevel === CONSTANTS.registration.COMPANY_ADDRESS) {
            setHeading({
                h1: 'Account Details',
                h2: 'Register payment details of your organization'
            })
        }
    }, [registerLevel])

    return (
        <div className="right position-relative">
            {
                registerLevel >= CONSTANTS.registration.EMAIL_VERIFIED && <>
                    <span className="topHeader-logout px-4 py-2">Not <a href="javascript:void(0)" rel="noopenner">{orgData.email}</a> ? 
                    <Button onClick={logoutHandler} className={`${classes.themeButton} ${classes.lightThemeButton} ${classes.noPadding} my-2 px-1 color-1-imp toLower font-10pt`}>
                        Logout
                    </Button>
                    </span>
                </>
            }
            <div className="d-flex h-100 auth--container_right">
                {
                    <div className="my-auto w-100 py-5">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column">
                                <h2 className="fsize-28">{heading.h1}</h2>
                                <h5 className="fsize-16">{heading.h2}</h5>
                            </div>
                            {
                                registerLevel === CONSTANTS.registration.EMAIL_VERIFIED ?
                                (
                                    payload.fields.companyLogoImage ?
                                    <div className="img-wrap">
                                        <img onClick={e => document.getElementById("companyLogoImage").click()} style={{width:"70px", height:"70px", borderRadius:"50%",objectFit:"cover", border: "1px dotted grey"}} className="img-fluid" src={payload.fields.companyLogoImage ? payload.fields.companyLogoImage.blob : ""} alt={"Company Logo"}/>
                                        <input name="companyLogoImage" id="companyLogoImage" onChange={inputHandler} type="file" accept="image/png,image/jpg" className="d-none"/>
                                    </div> : 
                                    <div className="d-flex align-items-center">
                                        <div className="img-wrap">
                                            <Button data-label="add-logo" onClick={e => document.getElementById("companyLogoImage").click()} className={`${classes.themeButton} ${classes.lightThemeButton} ${classes.noPadding} my-2 px-1 color-1-imp toLower`}>
                                                Add Logo
                                            </Button>
                                            <input name="companyLogoImage" id="companyLogoImage" onChange={inputHandler} type="file" accept="image/png,image/jpg" className="d-none"/>
                                        </div>
                                    </div>
                                    
                                ) : ""
                            }
                        </div>
                        <div className="mt-5">
                            <form autoComplete={"off"} ref={formRef} onSubmit={e => submitForm(e)} className="form">
                                <RegistrationTemplate
                                payload={payload.fields}
                                errorLoad={payload.error}
                                inputHandler={inputHandler}
                                step={registerLevel}
                                />
                                {
                                    payload.error && payload.error.companyLogoImage && <div className="muiErrorColor MuiFormHelperText-root pt-3">* {payload.error.companyLogoImage}</div>
                                }
                                <div className="btn-wrap d-flex justify-content-between mt-4 w-100">
                                    <Button type="submit" id="submitFormButton"
                                        variant="contained" className={`${classes.themeButton} w-100 py-2 br-2 transform-initial`}>
                                        {!stepper && registerLevel < 1 ? 'Signup' : (registerLevel === 1 ? 'Verify' : 'Next')}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                }
                {
                    !stepper &&
                    <div className="bottom-block">
                        {
                        !registerLevel ?
                        <div className="d-flex align-items-center w-100 justify-content-center">
                            <span className="fsize-16 color-21">Already have an account?</span>
                            <div className="text-center">
                                <NavLink className={classes.defaultNavLink} to={`/login`}>
                                    <Button className={`${classes.themeButton} ${classes.lightThemeButton} ${classes.noPadding} my-2 px-1 color-1-imp toLower`}>
                                        Login
                                    </Button>
                                </NavLink>
                            </div>
                        </div> : 
                        <div className="d-flex align-items-center w-100 justify-content-center">
                            <div className="text-center">
                                <Button onClick={logoutHandler} className={`${classes.themeButton} ${classes.lightThemeButton} ${classes.noPadding} my-2 px-1 color-1-imp toLower`}>
                                    Logout
                                </Button>
                            </div>
                        </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default connect(null, { logoutHandler })(RegisterComponent)