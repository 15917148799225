import { CONSTANTS } from "./_constants";
// import _ from 'lodash'

export const globalValidator = (type, operation, payload) => {
    switch (type) {
        case CONSTANTS.routeType.EMPLOYEE: {
            let fields = [];
            if (operation === CONSTANTS.operation.ROADBLOCK) {
                fields = ["selectedEmployee"]
            }
            let checkAuthenticity = checkIfAllKeysExist(payload, fields)
            if(checkAuthenticity) {
                return true
            }
            return {
                error: true,
                fields: [],
                message: "Please fill all the fields"
            }
        }
        default: return;
    }
}

export const validateURL = string => {
    if(string && !CONSTANTS.regex.url.test(string)) {
        return {
            url: "Please enter valid URL"
        }
    }
    return {}
}

export const validatePhoneNumber = string => {
    if(string && !CONSTANTS.regex.phoneRegex.test(`+1${string}`)) {
        return {
            contactPhoneNumber: "Please enter valid Phone Number"
        }
    }
    return {}
}

export const validatePassword = string => {
    if(string && !CONSTANTS.regex.password.test(string)) {
        return {
            password: "Password must be minimum eight characters, and contain at least one capital letter, one number and one special character."
        }
    }
    return {}
}

function checkIfAllKeysExist(object, fields) {
    return [...fields].every(p => object.hasOwnProperty(p))
}