import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import NotVerified from '../components/organisation/unverified'
import { CONSTANTS } from '../_utils/_constants'

function PrivateRoute({
    component : Component,
    isAuthenticated,
    registerLevel,
    isVerified,
    ...rest
}){
    const activatedRoute = `/login?to=${window.location.pathname + encodeURIComponent(window.location.search)}`
    
    return (
        <Route {...rest} render={props => (
            isAuthenticated && (isVerified || rest.computedMatch.params.routeType === "profile") ?
            <Component {...props} /> : isAuthenticated && !isVerified ? <NotVerified/> 
            : <Redirect to={registerLevel ? `/register?to=${window.location.pathname + encodeURIComponent(window.location.search)}` : activatedRoute}/>
        )} />
    )
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token && state.auth.step > CONSTANTS.registration.COMPANY_ADDRESS || false,
        registerLevel: (state.auth.token && state.auth.step < CONSTANTS.registration.FINAL) || false,
        isVerified: state.auth.isVerified || false
    }
}

export default connect(mapStateToProps, {})(PrivateRoute)