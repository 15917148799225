import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { headerStyles } from '../../_styles/mui';
import { NavLink } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import { logoutHandler } from '../../_actions/auth';
import { Avatar } from '@material-ui/core';
import _ from 'lodash'

const useStyles = (theme) => headerStyles(theme)

const Header = ({
    routeType,
    authLevel,
    logoutHandler,
    organisationDetails,
    constants,
    ...rest
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div className={classes.headerRoot}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        <NavLink className={`${classes.defaultNavLink} fsize-24`} to={"/"}>
                            <img src={"/logo.svg"} className="img-fluid" alt="Logo" style={{maxWidth:"150px"}}/>
                        </NavLink>
                    </Typography>
                    {
                        !routeType && 
                        <>
                        {
                            (authLevel || localStorage.getItem(process.env.REACT_APP_TOKEN)) ? 
                            <NavLink to={"/dashboard"} className={classes.defaultNavLink}>
                                <Button variant="contained" className={`${classes.themeButton} mr-3 transform-initial animBtnGrow`}>
                                    Dashboard
                                </Button>
                            </NavLink> : 
                            <NavLink to={"/register"} className={classes.defaultNavLink}>
                                <Button variant="contained" className={`${classes.themeButton} transform-initial animBtnGrow`}>
                                    Organization Signup
                                </Button>
                            </NavLink>
                        }
                        </>
                    }
                    {
                        routeType === "organisation" && 
                        <>
                        <Button variant="contained" aria-controls="profile-menu" 
                        aria-haspopup="true" onClick={handleClick}
                        className={`${classes.themeButton} ${classes.lightThemeButton}`} disableElevation>
                            <div className="img-wrap mr-2">
                                {
                                    organisationDetails.logoImageUri ? 
                                    <img src={`//${constants.s3BaseUrl}${organisationDetails.logoImageUri}`} className="img-fluid thumbnail" alt="Logo"/> : 
                                    <Avatar className={`bg-1-imp`}>{organisationDetails.companyName ? _.trim(organisationDetails.companyName).substr(0,1) : ""}</Avatar>
                                }
                            </div>
                            <span className="toLower">{organisationDetails.companyName}</span>
                            <span className="material-icons">keyboard_arrow_down</span>
                        </Button>
                        <Menu
                            id="profile-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={e => setAnchorEl(null)}
                            className="w-100"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center"
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left"
                            }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem onClick={e => setAnchorEl(null)} className={"w-150"}>
                                <NavLink className={classes.defaultNavLink} to={`/dashboard/profile`}>Account Details</NavLink>
                            </MenuItem>
                            <MenuItem onClick={e => {setAnchorEl(null); logoutHandler()}} className={"w-150"}>
                                Logout
                            </MenuItem>
                        </Menu>
                        </>
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
}

const mapStateToProps = state => ({
    authLevel: state.auth.token || false,
    organisationDetails: state.auth && state.auth.user ? state.auth.user : {},
    constants: state.records && state.records.constants ? state.records.constants : {}
})

export default connect(mapStateToProps, { logoutHandler })(Header)