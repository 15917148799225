import React from 'react'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody'
import { CONSTANTS } from './../../../../_utils/_constants'
import OrganisationTable from './../data/home'
import EmployeeTable from '../data/employee';
import TransactionTable from '../data/transaction';

function BodyIndex({
    handleClick,
    rows,
    rowsPerPage,
    loading,
    errorHandler,
    routeType,
    ...props
}) {
    return (
        <TableBody>
            {rows.map((row, index) => {
                // const labelId = `enhanced-table-checkbox-${index}`;
                return (
                    <React.Fragment key={index}>
                        {
                            routeType === CONSTANTS.routeType.HOME && 
                            <OrganisationTable
                            row={row}
                            />
                        }
                        {
                            routeType === CONSTANTS.routeType.EMPLOYEE && 
                            <EmployeeTable
                            row={row}
                            />
                        }
                        {
                            routeType === CONSTANTS.routeType.TRANSACTION && 
                            <TransactionTable
                            row={row}
                            />
                        }
                    </React.Fragment>
                )
            })}
            {(rowsPerPage - rows.length) > 0 && (loading || rows.length > 0) && (
                <TableRow style={{ height: 33 * (rowsPerPage - rows.length) }}>
                    <TableCell colSpan={11} align="center">
                        {
                            errorHandler && errorHandler.hasError ?
                            <div className="d-flex flex-column m-auto sky-t-er">
                                <img src="/assets/error.svg" alt="error"/>
                                <span className="mt-3 pt-3">{errorHandler.error || errorHandler.data || "Some error Occurred"}</span>
                            </div> : (loading ? "Loading.." : "")
                        }
                    </TableCell>
                </TableRow>
            )}
            {rows.length === 0 && !loading && (
                <TableRow style={{ height: 33 * (rowsPerPage - rows.length) }}>
                    <TableCell colSpan={11} align="center">
                        <div className="d-flex flex-column m-auto sky-t-er">
                            <img src={`assets/images/empty.svg`} style={{maxHeight: '150px'}} alt="no data found"/>
                            <span className="mt-3">No Data found</span>
                        </div>
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    )
}

export default BodyIndex