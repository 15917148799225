import { SET_TOOLS, RESET_MODAL, LOGOUT } from "../_actions/type"

const initialState = {
    show: false,
    hide: true
}

export default function (state = initialState, action) {
    const { type, payload } = action

    switch (type) {

        case SET_TOOLS: {
            return {
                ...payload
            }
        }

        case LOGOUT:
        case RESET_MODAL: {
            return {
                show: false
            }
        }

        default:
            return state
    }
}