import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes/router';
import BackDrop from './components/shared/backdrop'
import GlobalSnack from './components/shared/snack'

function App() {
  const showLoader = useSelector(state => state.loader)

  return (
    <React.Fragment>
      <BrowserRouter>
        <Router />
        <BackDrop showLoader={showLoader}/>
        <GlobalSnack/>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
