import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchRecords } from '../../_actions/common'
import { CONSTANTS } from '../../_utils/_constants'
import StripeAlert from '../reusable/_alertForRequirements'
import OrganisationHead from '../reusable/_organisationHead'
import TableIndex from './table/index'

function OrganisationIndex({
    match,
    organisationDetails,
    fetchRecords,
    ...rest
}) {
    const [routeType, setRouteType] = useState('')
    const [topAlert, setTopAlert] = useState({})
    const [storeDetails, setStoreDetails] = useState({})

    useEffect(() => {
        setRouteType(match.params.routeType || CONSTANTS.routeType.HOME)
        if(match.params.routeType) {
            fetchRecords({
                routeType: CONSTANTS.routeType.STORE,
                resourceId: match.params.resourceId,
                operationType: 'get'
            }, cb => {
                if(cb.data) {
                    setStoreDetails(cb.data)
                    if(cb.data.stripeConnectAccountRequirementsAndBankDetails && cb.data.stripeConnectAccountRequirementsAndBankDetails.stripeConnectAccountRequirements) {
                        setTopAlert(cb.data.stripeConnectAccountRequirementsAndBankDetails.stripeConnectAccountRequirements)
                    }
                }
            })
        }
        else {
            setStoreDetails({})
        }
    }, [match.params.routeType])

    return (
        <React.Fragment>
            <div className="organisation--container container">
                <StripeAlert
                payload={match.params.routeType === undefined ? organisationDetails.stripeConnectAccountRequirements : topAlert}
                type={match.params.routeType === undefined ? 1 : 2}
                />
                <OrganisationHead routeType={routeType || ""} storeDetails={storeDetails || {}}/>
                { routeType && !([CONSTANTS.routeType.PROFILE, CONSTANTS.routeType.STORE].indexOf(routeType) > -1) && <TableIndex routeType={routeType || ""}/> }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
  return {
    organisationDetails: state.auth && state.auth.user ? state.auth.user : {}
  }
}

export default connect(mapStateToProps, { fetchRecords })(withRouter(OrganisationIndex))