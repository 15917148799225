import React, { useEffect, useState } from 'react'
import { 
    stateCodeListItem, 
    CONSTANTS, 
    genderCodeList, 
    positionList,
    ageRangeCodeList
} from '../../_utils/_constants'
import { MuiCheckbox, MuiInput, MuiNativeSelect, MuiSelect } from './formUI'
import { fetchRecords, setEditRecord } from '../../_actions/common';
import { connect } from 'react-redux';
import _ from 'lodash'
import GoogleMapSearch from '../shared/GoogleMapSearch';
import GoogleMapComponent from '../shared/GoogleMapComponent';
import SearchEmployee from '../shared/SearchEmployee';
import { Avatar } from '@material-ui/core';
import { capitalize } from '../../_utils/_handlers';

import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';

export const EmployeeTemplate = connect(null, { fetchRecords, ageRangeCodeList, genderCodeList, positionList })(({
    operation, payload, inputHandler, disabled, fetchRecords, s3BaseUrl, ageRangeCodeList, genderCodeList, positionList
}) => {
    const [userList, setUserList] = React.useState([])
    const [ageRangeList, setAgeRangeList] = useState([])
    const [genderRangeList, setGenderCodeList] = useState([])
    const [jobList, setJobCodeList] = useState([])
    const loading = false; //userList.length === 0;

    useEffect(() => {
        (async () => {
            let response = await ageRangeCodeList()
            let genderList = await genderCodeList()
            let jobCodeList = await positionList()
            setAgeRangeList(response)
            setGenderCodeList(genderList)
            setJobCodeList(jobCodeList)
        })()
    }, [])

    const changeSearch = value => {
        if(value && value.length > 5) {
            fetchRecords({
                routeType: CONSTANTS.routeType.EMPLOYEE,
                operationType: CONSTANTS.operation.SEARCH,
                search: value
            }, callback => {
                if(callback.data && callback.data.length) {
                    setUserList(_.map(callback.data, e => {
                        return {
                            ...e,
                            value: e.id
                        }
                    }))
                }
            }, false)
        }
    }

    return (
        [CONSTANTS.operation.ADD, CONSTANTS.operation.EDIT, CONSTANTS.operation.VIEW].indexOf(operation) > -1 ? <React.Fragment>
            <div className="d-flex flex-sm-row flex-column mb-4 pb-4">
                <div className="img-wrap">
                    { 
                        payload.profileImageUri ? 
                        <img className="img-fluid thumbnail-lg" src={`//${s3BaseUrl}${payload.profileImageUri}`} alt="User Avatar"/>
                        : <Avatar className={`bg-1-imp avatar-img`}>{payload.name ? _.trim(payload.name).substr(0,1) : ""}</Avatar>
                        }
                </div>
                <div className="d-flex flex-column ml-sm-3 my-sm-auto mt-3">
                    <h5 className="mb-0">{payload.name}</h5>
                    <p className="mb-0">{payload.email}</p>
                </div>
            </div>
            <div className="form-wrap_row d-flex form-wrap_responsive">
                <div className="fb-40 mr-2 mt-md-0 mt-3">
                    <MuiInput 
                    options={{ 
                        name: "phoneNumber", 
                        value: payload['phoneNumber'], 
                        label: "Mobile Number", 
                        required: true,
                        disabled: true,
                        className:"mr-2 fb-50 mt-md-0 mt-3"
                    }} 
                    handler={inputHandler}/>
                </div>
                <div className="d-flex form-wrap_responsive fb-60 form-wrap_flex">
                    <MuiSelect 
                    options={{ 
                        name: "ageRangeCode", 
                        value: payload['ageRangeCode'], 
                        label: "Age", required: true, 
                        list: [...ageRangeList],
                        disabled: true
                    }} 
                    handler={inputHandler}
                    className="mr-2 fb-50 mt-md-0 mt-3"
                    />
                    <MuiSelect 
                    options={{ 
                        name: "genderCode", 
                        value: payload['genderCode'], 
                        label: "Gender", 
                        required: true, 
                        list: [...genderRangeList],
                        disabled: true
                    }} 
                    handler={inputHandler}
                    className="mr-2 fb-50 mt-md-0 mt-3"
                    />
                </div>
            </div>
            <div className="form-wrap_row d-flex form-wrap_responsive">
                <div className="fb-40 mr-2 mt-3">
                    <MuiNativeSelect 
                        options={{ 
                            name: "jobDesignationCode", 
                            value: payload['jobDesignationCode'], 
                            label: "Designation", 
                            required: true, 
                            list: [...jobList],
                            disabled,
                        }}
                        className="fb-50"
                        handler={inputHandler}
                    />
                </div>
            </div>
        </React.Fragment> : 
        ([CONSTANTS.operation.ROADBLOCK].indexOf(operation) > -1 ? 
        <React.Fragment>
            <div className="form-wrap_row d-flex flex-row">
                <div className="w-100 mr-2" id="employeeRoadblock">
                    <SearchEmployee
                    s3BaseUrl={s3BaseUrl}
                    fetchRecords={fetchRecords}
                    handler={inputHandler}
                    payload={payload}
                    />
                </div>
            </div>
        </React.Fragment> : 
        ([CONSTANTS.operation.REMOVE].indexOf(operation) > -1 ? <React.Fragment>
            <p className="mb-3">Are you sure you want to remove {payload.name} ({capitalize(payload.jobDesignationCode)}) from the store?</p>
        </React.Fragment>  : <></>))
    )
})

export const StoreTemplate = ({setHeading, payload, inputHandler, step = 1, disabled }) => {
    const [hardDisabled, setDisabled] = React.useState(false)
    const requiredArray = {storeName: true, taxId: true, storeAddress: true, onlineStore: true, email: true, url: true, phone: true, routing_number: true, account_number: true, confirm_routing_number: true, confirm_account_number: true};
    React.useEffect(() => {
        setHeading(step === 1 ? "Basic Information" : "Payment Information")
    }, [step])

    React.useEffect(() => {
        if(payload.sameAs) {
            setDisabled(true)
        }
        else {
            setDisabled(false)
        }
    }, [payload])

    return (
        step === 1 ? <React.Fragment>
            <div className="row">
                <div className="col-md-6">
                    <MuiInput 
                    options={{ 
                        name: "storeName", 
                        value: payload['storeName'], 
                        label: "Store Name", 
                        required: requiredArray.storeName,
                        disabled
                    }} 
                    className="mr-2 mt-0"
                    handler={inputHandler}/>
                </div>
                <div className="col-md-6">
                    <MuiInput 
                    options={{ 
                        name: "taxId", 
                        value: payload['taxId'], 
                        label: "Company Tax ID", 
                        required: requiredArray.taxId,
                        disabled
                    }} 
                    className="mr-2 mt-md-0 mt-4"
                    handler={inputHandler}/>
                </div>
                <div className="col-md-6">
                    <MuiInput 
                    options={{ 
                        name: "storeAddress", 
                        value: payload['storeAddress'], 
                        label: "Store Address", 
                        required: requiredArray.storeAddress,
                        disabled
                    }} 
                    className="mr-2 mt-4"
                    handler={inputHandler}/> 
                </div>
                <div className="col-md-6">
                    <MuiSelect 
                        options={{ 
                            name: "onlineStore", 
                            value: payload['onlineStore'], 
                            label: "Online Store", 
                            required: requiredArray.onlineStore, 
                            list: [{ name: "A", value: 1 }],
                            disabled,
                        }}
                        handler={inputHandler}
                        className="mr-2 mt-4 w-100"
                    />
                </div>
                <div className="col-md-6">
                    <MuiInput 
                    options={{ 
                        name: "email", 
                        value: payload['email'], 
                        label: "Email", 
                        required: requiredArray.email,
                        disabled,
                        type: "email"
                    }} 
                    className="mr-2 mt-4"
                    handler={inputHandler}/>
                </div>
                <div className="col-md-6">
                    <MuiInput 
                    options={{ 
                        name: "websiteUrl", 
                        value: payload['websiteUrl'], 
                        label: "Website URL", 
                        required: requiredArray.websiteUrl,
                        disabled
                    }} 
                    className="mr-2 mt-4"
                    handler={inputHandler}/>
                </div>
                <div className="col-md-6">
                    <MuiInput 
                    options={{ 
                        name: "phone", 
                        value: payload['phone'], 
                        label: "Phone Number", 
                        required: requiredArray.phone,
                        disabled
                    }} 
                    className="mr-2 mt-4"
                    handler={inputHandler}/>
                </div>
            </div>
        </React.Fragment> : <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <MuiCheckbox
                    options={{
                        name: "sameAs",
                        value: payload['sameAs'],
                        label: "Same Details as Organization"
                    }}
                    className="mr-2 mt-0"
                    handler={inputHandler}/>
                </div>
                <div className="col-md-6">
                    <MuiInput 
                    options={{ 
                        name: "accountNumber", 
                        value: payload['accountNumber'], 
                        label: "Account Number", 
                        required: requiredArray.accountNumber,
                        disabled: hardDisabled
                    }} 
                    className="mr-2 mt-2"
                    handler={inputHandler}/>
                </div>
                <div className="col-md-6">
                    <MuiInput 
                    options={{ 
                        name: "routing_number", 
                        value: payload['routing_number'], 
                        label: "Routing Number", 
                        required: requiredArray.routing_number,
                        disabled: hardDisabled
                    }} 
                    className="mr-2 mt-2"
                    handler={inputHandler}/>
                </div>
                <div className="col-md-6">
                    <MuiInput 
                    options={{ 
                        name: "confirmAccountNumber", 
                        value: payload['confirmAccountNumber'], 
                        label: "Confirm Account Number", 
                        required: requiredArray.confirmAccountNumber,
                        disabled: hardDisabled
                    }} 
                    className="mr-2 mt-4 mb-4"
                    handler={inputHandler}/>
                </div>
                <div className="col-md-6">
                    <MuiInput 
                    options={{ 
                        name: "confirm_routing_number", 
                        value: payload['confirm_routing_number'], 
                        label: "Confirm Routing Number", 
                        required: requiredArray.confirm_routing_number,
                        disabled: hardDisabled
                    }} 
                    className="mr-2 mt-4 mb-4"
                    handler={inputHandler}/>
                </div>
            </div>
        </React.Fragment>
    )
}

export const TransactionTemplate = ({payload}) => {
    return (
        <React.Fragment>
            <div className="table-responsive mb-4">
                <table className="table txTable">
                    <tbody>
                        <tr>
                            <th>Voucher Number</th>
                            <td>{payload.voucherInstanceId}</td>
                        </tr>
                        <tr>
                            <th>Transaction ID</th>
                            <td>{payload.id}</td>
                        </tr>
                        <tr>
                            <th>Cashier Name</th>
                            <td>{payload.userMessageText || "-"}</td>
                        </tr>
                        <tr>
                            <th>Date & Time</th>
                            <td>{payload.updatedAt ? new Date(payload.updatedAt).toLocaleDateString() : ""} {payload.updatedAt ? new Date(payload.updatedAt).toLocaleTimeString() : ""}</td>
                        </tr>
                        <tr>
                            <th>Recipient Name</th>
                            <td>{payload.recipientName || <div className="text-danger">NA</div>}</td>
                        </tr>
                        <tr>
                            <th>Amount</th>
                            <td>{payload.transactionAmountInSmallestUnit} <span style={{textTransform:"uppercase"}}>{payload.currencyCode}</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}

export const PhysicalStoreAddTemplate = connect(null, { stateCodeListItem })(({payload, inputHandler, step = 1, disabled = false, storeType, errorLoad, stateCodeListItem}) => {

    const [companyDetails, setCompanyDetails] = useState({})
    const [paymentDetails, setPaymentDetails] = useState({})
    const [stateCodeList, setStateCode] = useState([])
    const [countryList, setCountryList] = useState([])

    useEffect(() => {
        if(step === 2 && storeType === "physical") {
            stateCodeListItem('', false, cb => {
                setStateCode(cb)
            })
            stateCodeListItem('', true, cb => {
                setCountryList(cb)
            })
        }
    }, [step])

    useEffect(() => {
        if(payload.isAutofillCompanyDetailsFromParentOrganisation) {
            setCompanyDetails({
                disabled: true
            })
        }
        else {
            setCompanyDetails({
                disabled: false
            })
        }
    }, [payload.isAutofillCompanyDetailsFromParentOrganisation])

    useEffect(() => {
        if(payload.paymentDetailsSameAsOrganisation) {
            setPaymentDetails({
                disabled: true
            })
        }
        else {
            setPaymentDetails({
                disabled: false
            })
        }
    }, [payload.paymentDetailsSameAsOrganisation])

    return (
        <>
        {
            step === 1 && 
            <React.Fragment>
                <div className="d-flex flex-sm-row flex-column mt-4 mb-3">
                    <h5>Basic Details</h5>
                </div>
                <div className="row pb-4 mb-2">
                    <div className="col-md-6">
                        <MuiInput 
                        options={{ 
                            name: "storeName", 
                            value: payload['storeName'], 
                            label: "Store Name", 
                            required: true,
                            disabled
                        }} 
                        className="mr-2 mt-0"
                        handler={inputHandler}/>
                    </div>
                    <div className="col-md-6">
                        <MuiInput 
                        options={{ 
                            name: "storeIdentificationCode", 
                            value: payload['storeIdentificationCode'], 
                            label: "Store ID", 
                            required: true,
                            disabled
                        }} 
                        className="mr-2 mt-md-0 mt-4"
                        handler={inputHandler}/>
                    </div>
                    <div className="col-md-6">
                        <MuiInput 
                        options={{ 
                            name: "contactPhoneNumber", 
                            value: payload['contactPhoneNumber'], 
                            label: "Phone Number", 
                            required: true,
                            disabled
                        }} 
                        error={errorLoad['contactPhoneNumber'] ? true : false}
                        helperText={errorLoad['contactPhoneNumber'] || ""}
                        className="mr-2 mt-4"
                        handler={inputHandler}/>
                    </div>
                    <div className="col-md-6">
                        <MuiInput 
                        options={{ 
                            name: "url", 
                            value: payload['url'], 
                            label: "Website", 
                            required: storeType === "physical" ? false : true,
                            disabled: companyDetails.disabled
                        }} 
                        error={errorLoad['url'] ? true : false}
                        helperText={errorLoad['url'] || ""}
                        className="mr-2 mt-4"
                        handler={inputHandler}/>
                    </div>
                </div>
            </React.Fragment>
        }
        {
            step === 2 && storeType === "physical" && 
            <React.Fragment>
                <div className="d-flex flex-sm-row flex-column mt-4 mb-3">
                    <h5>Location Details</h5>
                </div>
                <div className="row">
                    <div className="col-md-6" id="google-map-store">
                        <GoogleMapSearch
                        required
                        payload={payload}
                        className="w-100"
                        handler={inputHandler}
                        />
                        <div className="mt-4">
                            <GoogleMapComponent
                            zoom={payload.locationCoordinates && payload.locationCoordinates.coordinates ? 16 : 6}
                            height={'250px'}
                            coordinates={payload.locationCoordinates && payload.locationCoordinates.coordinates && payload.locationCoordinates.coordinates.length ? { lat: payload.locationCoordinates.coordinates[1], lng: payload.locationCoordinates.coordinates[0] } : { lat: 39.8868751, lng: -99.5605614}}
                            placeName={payload.locationSearch && payload.locationSearch.description ? payload.locationSearch.description : ""}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <MuiInput 
                        options={{ 
                            name: "officeAndBuildingNumber", 
                            value: payload['officeAndBuildingNumber'], 
                            label: "Address Line 1", 
                            required: true,
                            disabled
                        }} 
                        className="mr-2 mt-0"
                        handler={inputHandler}/>
                        <MuiInput 
                        options={{ 
                            name: "streetOrLocalityName", 
                            value: payload['streetOrLocalityName'], 
                            label: "Address Line 2", 
                            required: false,
                            disabled
                        }} 
                        className="mr-2 mt-4"
                        handler={inputHandler}/>
                        <div className="d-flex flex-row">
                            <MuiInput 
                            options={{ 
                                name: "city", 
                                value: payload['city'], 
                                label: "City", 
                                required: true,
                                disabled
                            }} 
                            className="w-100 flex-fill mt-4 mr-2"
                            handler={inputHandler}/>
                            <MuiSelect 
                            options={{ 
                                name: "state", 
                                value: payload['state'], 
                                label: "State", 
                                required: true, 
                                list: stateCodeList,
                                disabled
                            }} 
                            handler={inputHandler}
                            className="mr-0 w-100 flex-fill mt-4 removeDropIcon"
                            />
                        </div>
                        <MuiInput 
                        options={{ 
                            name: "zipCode", 
                            value: payload['zipCode'], 
                            label: "Zip Code", 
                            required: true,
                            disabled
                        }} 
                        className="mr-2 mt-4"
                        handler={inputHandler}/>
                        <MuiNativeSelect 
                        options={{ 
                            name: "country", 
                            value: countryList.length === 1 ? countryList[0].value : payload['country'], 
                            label: "Country", 
                            required: true, 
                            list: countryList,
                            disabled
                        }} 
                        handler={inputHandler}
                        className="mr-0 w-100 flex-fill mt-4"
                        />
                    </div>
                </div>
            </React.Fragment>
        }
        {
            ((step === 3 && storeType === "physical") || (step === 2 && storeType === "online")) && 
            <React.Fragment>
                {/* <AddGoogleMap/> */}
                <div className="d-flex flex-sm-row flex-column mt-4 mb-3">
                    <h5>Company Details</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <MuiCheckbox
                        options={{
                            name: "isAutofillCompanyDetailsFromParentOrganisation",
                            value: payload['isAutofillCompanyDetailsFromParentOrganisation'],
                            label: "Same as Organization"
                        }}
                        className="mr-2"
                        handler={inputHandler}/>
                    </div>
                    <div className="col-md-6">
                        <MuiInput 
                        options={{ 
                            name: "companyName", 
                            value: payload['companyName'], 
                            label: "Company Name", 
                            required: true,
                            disabled: companyDetails.disabled
                        }} 
                        className="mr-2 mt-3"
                        handler={inputHandler}/>
                    </div>
                    <div className="col-md-6">
                        <MuiInput 
                        options={{ 
                            name: "companyDescription", 
                            value: payload['companyDescription'], 
                            label: "Company Description", 
                            required: true,
                            disabled: companyDetails.disabled
                        }} 
                        className="mr-2 mt-3"
                        handler={inputHandler}/>
                    </div>
                    <div className="col-md-6">
                        <MuiInput 
                        options={{ 
                            name: "taxIdentificationCode", 
                            value: payload['taxIdentificationCode'], 
                            label: "Company Tax ID", 
                            required: true,
                            disabled: companyDetails.disabled
                        }} 
                        className="mr-2 mt-3"
                        handler={inputHandler}/>
                    </div>
                </div>
            </React.Fragment>
        }
        {
            ((step === 4 && storeType === "physical") || (step === 3 && storeType === "online")) && 
            <React.Fragment>
                <div className="d-flex flex-sm-row flex-column mt-4 mb-3">
                    <h5>Bank Details</h5>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <MuiCheckbox
                        options={{
                            name: "paymentDetailsSameAsOrganisation",
                            value: payload['paymentDetailsSameAsOrganisation'],
                            label: "Same as Organization"
                        }}
                        className="mr-2"
                        handler={inputHandler}/>
                    </div>
                    <div className="col-md-6">
                        <MuiInput 
                        options={{ 
                            name: "account_holder_name", 
                            value: payload['account_holder_name'], 
                            label: "Account Holder Name", 
                            required: true,
                            disabled: paymentDetails.disabled
                        }} 
                        className="mr-2 mt-4"
                        handler={inputHandler}/>
                    </div>
                    <div className="col-md-6">
                        <MuiNativeSelect 
                        options={{ 
                            name: "account_holder_type", 
                            value: payload['account_holder_type'], 
                            label: "Account Holder Type", 
                            required: true, 
                            disabled: paymentDetails.disabled,
                            list: [{ name: "Individual", value: 'individual' }, { name: "Company", value: 'company' }]
                        }} 
                        handler={inputHandler}
                        className="mr-2 w-100 mt-4"
                        />
                    </div>
                    <div className="col-md-6">
                        <MuiInput 
                        options={{ 
                            name: "account_number", 
                            value: payload['account_number'], 
                            label: "Account Number", 
                            required: true
                        }} 
                        className="mr-2 mt-4"
                        error={errorLoad['account_number'] ? true : false}
                        helperText={paymentDetails.disabled ? "Please enter account number manually for security reasons" : ""}
                        handler={inputHandler}/>
                    </div>
                    <div className="col-md-6">
                        <MuiInput 
                        options={{ 
                            name: "confirm_account_number", 
                            value: payload['confirm_account_number'], 
                            label: "Confirm Account Number", 
                            required: true
                        }} 
                        error={errorLoad['confirm_account_number'] ? true : false}
                        helperText={errorLoad['confirm_account_number'] || ""}
                        className="mr-2 mt-4"
                        handler={inputHandler}/>
                    </div>
                    <div className="col-md-6">
                        <MuiInput 
                        options={{ 
                            name: "routing_number", 
                            value: payload['routing_number'], 
                            label: "Routing Number", 
                            required: true,
                            disabled: paymentDetails.disabled
                        }} 
                        error={errorLoad['routing_number'] ? true : false}
                        className="mr-2 mt-4"
                        handler={inputHandler}/>
                    </div>
                    <div className="col-md-6">
                        <MuiInput 
                        options={{ 
                            name: "confirm_routing_number", 
                            value: payload['confirm_routing_number'], 
                            label: "Confirm Routing Number", 
                            required: true,
                            disabled: paymentDetails.disabled
                        }} 
                        error={errorLoad['confirm_routing_number'] ? true : false}
                        helperText={errorLoad['confirm_routing_number'] || ""}
                        className="mr-2 mt-4"
                        handler={inputHandler}/>
                    </div>
                </div>
            </React.Fragment>
        }
        </>
    )
})

export const EditStoreTemplate = connect(null, { stateCodeListItem, setEditRecord })(({ stateCodeListItem, payload, setPayload, submitForm, inputHandler, storeType, errorLoad, loading, setEditRecord }) => {    
    const [stateCodeList, setStateCode] = useState([])
    const [basicToggle, setBasicToggle] = useState(true)
    const [paymentToggle, setPaymentToggle] = useState(true)
    const [taxToggle, setTaxToggle] = useState(true)
    const [locationToggle, setLocationToggle] = useState(true)
    const [countryList, setCountryList] = useState([])

    useEffect(() => {
        if(storeType === "physical") {
            stateCodeListItem('', false, cb => {
                setStateCode(cb)
            })
            stateCodeListItem('', true, cb => {
                setCountryList(cb)
            })
        }
    }, [storeType])

    useEffect(() => {
        setPayload({
            ...payload,
            account_number: !paymentToggle ? "" : `********${payload.account_number_last4 ? payload.account_number_last4 : "****"}`
        })
    }, [paymentToggle])

    useEffect(() => {
        setPayload({
            ...payload,
            taxIdentificationCode: !taxToggle ? "" : `*********`
        })
    }, [taxToggle])

    useEffect(() => {
        if(!basicToggle || !paymentToggle || !taxToggle || !locationToggle) {
            setEditRecord(1, () => {})
        }
        else if(basicToggle && paymentToggle && taxToggle && locationToggle) {
            setEditRecord(2, () => {})
        }
    }, [basicToggle, paymentToggle, taxToggle, locationToggle])

    return (
        <div className="row mt-5 mb-5 pb-5">
            <div className="d-flex flex-column position-relative col-12">
                <form className="w-100" autoComplete={"off"} onSubmit={e => submitForm(e, 0, cb => { if(!cb.hasError) { setBasicToggle(true) } })}>
                    <div className="d-flex flex-sm-row flex-column mt-0">
                        <h5>Basic Details</h5>
                        { basicToggle && <button className="ml-auto btn editBtn" onClick={e => setBasicToggle(basicToggle ? false : true)}><i className="material-icons">edit</i></button>}
                        {
                            !basicToggle && 
                            <div className="ml-auto">
                                <div className="d-flex flex-row">
                                    <button className="btn editBtn px-4" type="submit">Save</button>
                                    <button className="btn editBtn ml-2 bg-trans" onClick={e => setBasicToggle(basicToggle ? false : true)}>
                                        <i className="material-icons">close</i>
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="form-wrap_row row">
                        <div className="col-md-6">
                            <MuiInput 
                            options={{ 
                                name: "storeName", 
                                value: payload['storeName'], 
                                label: "Store Name", 
                                required: true,
                                disabled: basicToggle
                            }} 
                            className="mr-2 mt-0"
                            handler={inputHandler}/>
                        </div>
                        <div className="col-md-6">
                            <MuiInput 
                            options={{ 
                                name: "storeIdentificationCode", 
                                value: payload['storeIdentificationCode'], 
                                label: "Store Id", 
                                required: true,
                                disabled: basicToggle
                            }} 
                            className="mr-2 mt-md-0 mt-4"
                            handler={inputHandler}/>
                        </div>
                        <div className="col-md-6">
                            <MuiInput 
                            options={{ 
                                name: "contactPhoneNumber", 
                                value: payload['contactPhoneNumber'], 
                                label: "Phone Number", 
                                required: true,
                                disabled: basicToggle
                            }} 
                            error={errorLoad['contactPhoneNumber'] ? true : false}
                            helperText={errorLoad['contactPhoneNumber'] || ""}
                            className="mr-2 mt-4"
                            handler={inputHandler}/>
                        </div>
                        <div className="col-md-6">
                            <MuiInput 
                            options={{ 
                                name: "url", 
                                value: payload['url'], 
                                label: "Website", 
                                required: storeType && storeType === "physical" ? false : true,
                                disabled: basicToggle
                            }} 
                            error={errorLoad['url'] ? true : false}
                            helperText={errorLoad['url'] || ""}
                            className="mr-2 mt-4"
                            handler={inputHandler}/>
                        </div>
                    </div>
                </form>
                { loading === 0 && <div className="contained-overlay"></div>}
            </div>

            <div className="d-flex flex-column position-relative col-12 mt-5">
                <form className="w-100" autoComplete={"off"} onSubmit={e => submitForm(e, 1, cb => { if(!cb.hasError) { setPaymentToggle(true) } })}>
                    <div className="d-flex flex-sm-row flex-column mt-0">
                        <h5>Bank Details</h5>
                        { paymentToggle && <button className="ml-auto btn editBtn" onClick={e => setPaymentToggle(paymentToggle ? false : true)}><i className="material-icons">edit</i></button>}
                        {
                            !paymentToggle && 
                            <div className="ml-auto">
                                <div className="d-flex flex-row">
                                    <button className="btn editBtn px-4" type="submit">Save</button>
                                    <button className="btn editBtn ml-2 bg-trans" onClick={e => setPaymentToggle(paymentToggle ? false : true)}>
                                        <i className="material-icons">close</i>
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="form-wrap_row row">
                        <div className="col-md-6">
                            <MuiInput 
                            options={{ 
                                name: "account_holder_name", 
                                value: payload['account_holder_name'], 
                                label: "Account Holder Name", 
                                required: true,
                                disabled: paymentToggle
                            }}
                            className="mt-0"
                            handler={inputHandler}/>
                        </div>
                        <div className="col-md-6">
                            <MuiNativeSelect
                            options={{ 
                                name: "account_holder_type", 
                                value: payload['account_holder_type'], 
                                label: "Account Holder Type", 
                                required: true, 
                                list: [{ name: "Individual", value: 'individual' }, { name: "Company", value: 'company' }],
                                disabled: paymentToggle
                            }} 
                            handler={inputHandler}
                            className="mr-2 w-100 mt-4 mt-md-0"
                            />
                        </div>
                        <div className="col-md-6">
                            <MuiInput 
                            options={{ 
                                name: "account_number", 
                                value: payload['account_number'], 
                                label: "Account Number", 
                                required: true,
                                disabled: paymentToggle
                            }}
                            helperText={!paymentToggle ? "Please enter account number manually for security reasons" : ""}
                            className="mt-4"
                            handler={inputHandler}/>
                        </div>
                        <div className="col-md-6">
                            <MuiInput 
                            options={{ 
                                name: "routing_number", 
                                value: payload['routing_number'], 
                                label: "Routing Number", 
                                required: true,
                                disabled: paymentToggle
                            }}
                            className="mt-4"
                            handler={inputHandler}/>
                        </div>
                    </div>
                </form>
                { loading === 1 && <div className="contained-overlay"></div>}
            </div>

            <div className="d-flex flex-column position-relative col-md-12 mt-4">
                <form className="w-100" autoComplete={"off"} onSubmit={e => submitForm(e, 2, cb => { if(!cb.hasError) { setTaxToggle(true) } })}>
                    <div className="d-flex flex-sm-row flex-column mt-3">
                        <h5>Company Details</h5>
                            { taxToggle && <button className="ml-auto btn editBtn" onClick={e => setTaxToggle(taxToggle ? false : true)}><i className="material-icons">edit</i></button>}
                            {
                                !taxToggle && 
                                <div className="ml-auto">
                                    <div className="d-flex flex-row">
                                        <button className="btn editBtn px-4" type="submit">Save</button>
                                        <button className="btn editBtn ml-2 bg-trans" onClick={e => setTaxToggle(taxToggle ? false : true)}>
                                            <i className="material-icons">close</i>
                                        </button>
                                    </div>
                                </div>
                            }
                    </div>
                    <div className="form-wrap_row row">
                        <div className="col-md-6">
                            <MuiInput 
                            options={{ 
                                name: "companyName", 
                                value: payload['companyName'], 
                                label: "Company Name", 
                                required: true,
                                disabled: taxToggle
                            }}
                            className="mt-0"
                            handler={inputHandler}/>
                            <MuiInput 
                            options={{ 
                                name: "taxIdentificationCode", 
                                value: payload['taxIdentificationCode'], 
                                label: "Tax Id", 
                                required: true,
                                disabled: taxToggle
                            }}
                            className="mt-4"
                            handler={inputHandler}/>
                        </div>
                        <div className="col-md-6">
                            <MuiInput 
                            options={{ 
                                name: "companyDescription", 
                                value: payload['companyDescription'], 
                                label: "Company Description", 
                                required: true,
                                disabled: taxToggle,
                                row: 5
                            }}
                            className="mt-0"
                            handler={inputHandler}/>
                        </div>
                    </div>
                </form>
                { loading === 2 && <div className="contained-overlay"></div>}
            </div>

            {
            storeType && storeType === "physical" ?
            <div className="d-flex flex-column position-relative col-md-12 mt-4">
                <form className="w-100" autoComplete={"off"} onSubmit={e => submitForm(e, 3, cb => { if(!cb.hasError) { setLocationToggle(true) } })}>
                    <div className="d-flex flex-sm-row flex-column mt-4 mb-0">
                        <h5>Store Location</h5>                            
                        { locationToggle && <button className="ml-auto btn editBtn" onClick={e => setLocationToggle(locationToggle ? false : true)}><i className="material-icons">edit</i></button>}
                        {
                            !locationToggle && 
                            <div className="ml-auto">
                                <div className="d-flex flex-row">
                                    <button className="btn editBtn px-4" type="submit">Save</button>
                                    <button className="btn editBtn ml-2 bg-trans" onClick={e => setLocationToggle(locationToggle ? false : true)}>
                                        <i className="material-icons">close</i>
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="row pb-4 mb-2">
                        <div className="col-md-6">
                            <div className="form-wrap_row d-flex flex-column">
                                <MuiInput 
                                options={{ 
                                    name: "officeAndBuildingNumber", 
                                    value: payload['officeAndBuildingNumber'], 
                                    label: "Address Line 1", 
                                    required: true,
                                    disabled: locationToggle
                                }} 
                                className="mr-2 mt-0"
                                handler={inputHandler}/>
                                <MuiInput 
                                options={{ 
                                    name: "streetOrLocalityName", 
                                    value: payload['streetOrLocalityName'], 
                                    label: "Address Line 2", 
                                    required: false,
                                    disabled: locationToggle
                                }} 
                                className="mr-2 mt-4"
                                handler={inputHandler}/>
                                <div className="d-flex flex-row w-100">
                                    <MuiInput 
                                        options={{ 
                                            name: "city", 
                                            value: payload['city'], 
                                            label: "City", 
                                            required: true,
                                            disabled: locationToggle
                                        }} 
                                        className="mt-4 w-100 mr-2"
                                        handler={inputHandler}
                                    />
                                    <MuiNativeSelect
                                    options={{ 
                                        name: "state", 
                                        value: payload['state'], 
                                        label: "State", 
                                        required: true, 
                                        list: stateCodeList,
                                        disabled: locationToggle
                                    }} 
                                    handler={inputHandler}
                                    className="w-100 mt-4 mr-0"
                                    />
                                </div>
                                <MuiInput 
                                options={{ 
                                    name: "zipCode", 
                                    value: payload['zipCode'], 
                                    label: "Zip Code", 
                                    required: true,
                                    disabled: locationToggle
                                }} 
                                className="mr-2 mt-4"
                                handler={inputHandler}/>
                                <MuiNativeSelect
                                options={{ 
                                    name: "country", 
                                    value: countryList.length === 1 ? countryList[0].value : payload['country'], 
                                    label: "Country", 
                                    required: true, 
                                    list: countryList,
                                    disabled: locationToggle
                                }} 
                                handler={inputHandler}
                                className="w-100 mt-4"
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-wrap_row d-flex flex-column" id="google-map-store">
                            {
                                !locationToggle && 
                                <GoogleMapSearch
                                required
                                payload={payload}
                                className="w-100 mb-4"
                                handler={inputHandler}/>
                            }
                                <div>
                                    <GoogleMapComponent
                                    className=""
                                    zoom={16}
                                    height={'350px'}
                                    coordinates={payload.locationCoordinates && payload.locationCoordinates.coordinates && payload.locationCoordinates.coordinates.length ? { lat: payload.locationCoordinates.coordinates[1], lng: payload.locationCoordinates.coordinates[0] } : {}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                { loading === 3 && <div className="contained-overlay"></div>}
            </div> : ""
            }
        </div>
    )

})

export const ProfileTemplate = connect(null, { stateCodeListItem, setEditRecord })(({ payload, inputHandler, loading, submitForm, setPayload, stateCodeListItem, errorLoad, setEditRecord }) => {

    const [companyToggle, setCompanyToggle] = useState(true)
    const [paymentToggle, setPaymentToggle] = useState(true)
    const [taxToggle, setTaxToggle] = useState(true)
    const [locationToggle, setLocationToggle] = useState(true)
    const [stateCodeList, setStateCode] = useState([])
    const [countryList, setCountryList] = useState([])

    useEffect(() => {
        setPayload({
            ...payload,
            account_number: !paymentToggle ? "" : `********${payload.account_number_last4}`
        })
    }, [paymentToggle])

    useEffect(() => {
        setPayload({
            ...payload,
            taxIdentificationCode: !taxToggle ? "" : `*********`
        })
    }, [taxToggle])

    useEffect(() => {
        setTimeout(() => {
            stateCodeListItem('', false, cb => {
                setStateCode(cb)
            })
            stateCodeListItem('', true, cb => {
                setCountryList(cb)
            })
        }, 1000)
    }, [stateCodeListItem])

    useEffect(() => {
        if(!companyToggle || !paymentToggle || !taxToggle || !locationToggle) {
            setEditRecord(1, () => {})
        }
        else if(companyToggle && paymentToggle && taxToggle && locationToggle) {
            setEditRecord(2, () => {})
        }
    }, [companyToggle, paymentToggle, taxToggle, locationToggle])

    return (
        <React.Fragment>
            <div className="row mt-5 mb-5 pb-5">
                <div className="d-flex flex-column position-relative col-12">
                    <form className="w-100" autoComplete={"off"} onSubmit={e => submitForm(e, 0, cb => { if(!cb.hasError) { setCompanyToggle(true) } })}>
                        <div className="d-flex flex-sm-row flex-column mt-3">
                            <h5>Company Details</h5>
                            { companyToggle && <button className="ml-auto btn editBtn" onClick={e => setCompanyToggle(companyToggle ? false : true)}><i className="material-icons">edit</i></button>}
                            {
                                !companyToggle && 
                                <div className="ml-auto">
                                    <div className="d-flex flex-row">
                                        <button className="btn editBtn px-4" type="submit">Save</button>
                                        <button className="btn editBtn ml-2 bg-trans" onClick={e => setCompanyToggle(companyToggle ? false : true)}>
                                            <i className="material-icons">close</i>
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="form-wrap_row row">
                            <div className="col-md-6">
                                <MuiInput 
                                options={{ 
                                    name: "companyName", 
                                    value: payload['companyName'], 
                                    label: "Company Name", 
                                    required: true,
                                    disabled: companyToggle
                                }} 
                                className="mr-2 mt-0"
                                handler={inputHandler}/>
                                <MuiInput 
                                options={{ 
                                    name: "url", 
                                    value: payload['url'], 
                                    label: "URL", 
                                    required: true,
                                    disabled: companyToggle
                                }} 
                                error={errorLoad['url'] ? true : false}
                                helperText={errorLoad['url'] || ""}
                                className="mr-2 mt-4"
                                handler={inputHandler}/>
                                <MuiInput 
                                options={{ 
                                    name: "contactPhoneNumber", 
                                    value: payload['contactPhoneNumber'], 
                                    label: "Phone Number", 
                                    required: true,
                                    disabled: companyToggle
                                }} 
                                error={errorLoad['contactPhoneNumber'] ? true : false}
                                helperText={errorLoad['contactPhoneNumber'] || ""}
                                className="mr-2 mt-4"
                                handler={inputHandler}/>
                            </div>
                            <div className="col-md-6">
                                <MuiInput 
                                options={{ 
                                    name: "companyDescription", 
                                    value: payload['companyDescription'], 
                                    label: "Company Description", 
                                    required: true,
                                    disabled: companyToggle,
                                    row: 9
                                }} 
                                className="mr-2 mt-md-0 mt-4 h-100"
                                handler={inputHandler}/>
                            </div>
                        </div>
                    </form>
                    { loading === 0 && <div className="contained-overlay"></div>}
                </div>

                <div className="d-flex flex-column position-relative col-12 mt-4">
                    <form className="w-100" autoComplete={"off"} onSubmit={e => submitForm(e, 1, cb => { if(!cb.hasError) { setPaymentToggle(true) } })}>
                        <div className="d-flex flex-sm-row flex-column mt-3">
                            <h5>Bank Details</h5>
                                { paymentToggle && <button className="ml-auto btn editBtn" onClick={e => setPaymentToggle(paymentToggle ? false : true)}><i className="material-icons">edit</i></button>}
                                {
                                    !paymentToggle && 
                                    <div className="ml-auto">
                                        <div className="d-flex flex-row">
                                            <button className="btn editBtn px-4" type="submit">Save</button>
                                            <button className="btn editBtn ml-2 bg-trans" onClick={e => setPaymentToggle(paymentToggle ? false : true)}>
                                                <i className="material-icons">close</i>
                                            </button>
                                        </div>
                                    </div>
                                }
                        </div>
                        <div className="form-wrap_row row">
                            <div className="col-md-6">
                                <MuiInput 
                                options={{ 
                                    name: "account_holder_name", 
                                    value: payload['account_holder_name'], 
                                    label: "Account Holder Name", 
                                    required: true,
                                    disabled: paymentToggle
                                }}
                                className="mt-0"
                                handler={inputHandler}/>
                            </div>
                            <div className="col-md-6">
                                <MuiNativeSelect
                                options={{ 
                                    name: "account_holder_type", 
                                    value: payload['account_holder_type'], 
                                    label: "Account Holder Type", 
                                    required: true, 
                                    list: [{ name: "Individual", value: 'individual' }, { name: "Company", value: 'company' }],
                                    disabled: paymentToggle
                                }} 
                                handler={inputHandler}
                                className="mr-2 w-100 mt-4 mt-md-0"
                                />
                            </div>
                            <div className="col-md-6">
                                <MuiInput 
                                options={{ 
                                    name: "account_number", 
                                    value: payload['account_number'], 
                                    label: "Account Number", 
                                    required: true,
                                    disabled: paymentToggle
                                }}
                                helperText={!paymentToggle ? "Please enter account number manually for security reasons" : ""}
                                className="mt-4"
                                handler={inputHandler}/>
                            </div>
                            <div className="col-md-6">
                                <MuiInput 
                                options={{ 
                                    name: "routing_number", 
                                    value: payload['routing_number'], 
                                    label: "Routing Number", 
                                    required: true,
                                    disabled: paymentToggle
                                }}
                                className="mt-4"
                                handler={inputHandler}/>
                            </div>
                        </div>
                    </form>
                    { loading === 1 && <div className="contained-overlay"></div>}
                </div>

                <div className="d-flex flex-column position-relative col-md-6 mt-4">
                    <form className="w-100" autoComplete={"off"} onSubmit={e => submitForm(e, 2, cb => { if(!cb.hasError) { setTaxToggle(true) } })}>
                        <div className="d-flex flex-sm-row flex-column mt-3">
                            <h5>Tax Information</h5>
                                { taxToggle && <button className="ml-auto btn editBtn" onClick={e => setTaxToggle(taxToggle ? false : true)}><i className="material-icons">edit</i></button>}
                                {
                                    !taxToggle && 
                                    <div className="ml-auto">
                                        <div className="d-flex flex-row">
                                            <button className="btn editBtn px-4" type="submit">Save</button>
                                            <button className="btn editBtn ml-2 bg-trans" onClick={e => setTaxToggle(taxToggle ? false : true)}>
                                                <i className="material-icons">close</i>
                                            </button>
                                        </div>
                                    </div>
                                }
                        </div>
                        <div className="form-wrap_row row">
                            <div className="col-12">
                                <MuiInput 
                                options={{ 
                                    name: "taxIdentificationCode", 
                                    value: payload['taxIdentificationCode'], 
                                    label: "Tax Id", 
                                    required: true,
                                    disabled: taxToggle
                                }}
                                className="mt-0"
                                handler={inputHandler}/>
                            </div>
                        </div>
                    </form>
                    { loading === 2 && <div className="contained-overlay"></div>}
                </div>

                <div className="d-flex flex-column position-relative col-md-12 mt-4">
                    <form className="w-100" autoComplete={"off"} onSubmit={e => submitForm(e, 3, cb => { if(!cb.hasError) { setLocationToggle(true) } })}>
                        <div className="d-flex flex-sm-row flex-column mt-3">
                            <h5>Location Details</h5>
                                { locationToggle && <button className="ml-auto btn editBtn" onClick={e => setLocationToggle(locationToggle ? false : true)}><i className="material-icons">edit</i></button>}
                                {
                                    !locationToggle && 
                                    <div className="ml-auto">
                                        <div className="d-flex flex-row">
                                            <button className="btn editBtn px-4" type="submit">Save</button>
                                            <button className="btn editBtn ml-2 bg-trans" onClick={e => setLocationToggle(locationToggle ? false : true)}>
                                                <i className="material-icons">close</i>
                                            </button>
                                        </div>
                                    </div>
                                }
                        </div>
                        <div className="form-wrap_row row">
                            <div className="col-md-6">
                                <MuiInput 
                                options={{ 
                                    name: "officeAndBuildingNumber", 
                                    value: payload['officeAndBuildingNumber'], 
                                    label: "Address Line 1", 
                                    required: true,
                                    disabled: locationToggle
                                }}
                                className="mt-0"
                                handler={inputHandler}/>
                            </div>
                            <div className="col-md-6">
                                <MuiInput 
                                options={{ 
                                    name: "streetOrLocalityName", 
                                    value: payload['streetOrLocalityName'], 
                                    label: "Address Line 2", 
                                    required: false,
                                    disabled: locationToggle
                                }}
                                className="mt-4 mt-md-0"
                                handler={inputHandler}/>
                            </div>
                            <div className="col-md-6">
                                <MuiInput 
                                options={{ 
                                    name: "city", 
                                    value: payload['city'], 
                                    label: "City", 
                                    required: true,
                                    disabled: locationToggle
                                }}
                                className="mt-4"
                                handler={inputHandler}/>
                            </div>
                            <div className="col-md-6">
                                <MuiNativeSelect
                                options={{ 
                                    name: "state", 
                                    value: payload['state'], 
                                    label: "State", 
                                    required: true, 
                                    list: stateCodeList,
                                    disabled: locationToggle
                                }} 
                                handler={inputHandler}
                                className="w-100 mt-4"
                                />
                            </div>
                            <div className="col-md-6">
                                <MuiInput 
                                options={{ 
                                    name: "zipCode", 
                                    value: payload['zipCode'], 
                                    label: "Zip Code", 
                                    required: true,
                                    disabled: locationToggle
                                }}
                                className="mt-4"
                                handler={inputHandler}/>
                            </div>
                            <div className="col-md-6">
                                <MuiNativeSelect
                                options={{ 
                                    name: "country", 
                                    value: countryList.length === 1 ? countryList[0].value : payload['country'], 
                                    label: "Country", 
                                    required: true, 
                                    list: countryList,
                                    disabled: locationToggle
                                }} 
                                handler={inputHandler}
                                className="w-100 mt-4"
                                />
                            </div>
                        </div>
                    </form>
                    { loading === 3 && <div className="contained-overlay"></div>}
                </div>
            </div>
        </React.Fragment>
    )
})

export const RegistrationTemplate = connect(null, { stateCodeListItem })(({ inputHandler, payload, step, stateCodeListItem, errorLoad }) => {
    const [stateCodeList, setStateCode] = useState([])
    const [countryList, setCountryList] = useState([])

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState();

    const handleClick = (event, newPlacement = 'left') => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    useEffect(() => {
        if(step === 3) {
            stateCodeListItem('', false, cb => {
                setStateCode(cb)
            })
            stateCodeListItem('', true, cb => {
                setCountryList(cb)
            })
        }
    }, [step])
    return (
        <React.Fragment>
        {
            !step && 
            <>
                <MuiInput 
                options={{ 
                    name: "email", 
                    value: payload['email'], 
                    label: "Email", 
                    required: true,
                    type: "email"
                }}
                handler={inputHandler}/>
                <MuiInput 
                options={{ 
                    name: "password", 
                    value: payload['password'], 
                    label: "Password", 
                    type: "password",
                    required: true
                }}
                handleClick={e => handleClick(e, 'left')}
                helperText={errorLoad['password'] || ""}
                className="mt-4 flex-fill"
                error={errorLoad['password'] ? true : false}
                handler={inputHandler}/>
                <MuiInput 
                options={{ 
                    name: "confirm-password", 
                    value: payload['confirm-password'], 
                    label: "Confirm Password", 
                    type: "password",
                    required: true
                }}
                helperText={errorLoad['confirm-password'] || ""}
                className="mt-4"
                error={errorLoad['confirm-password'] ? true : false}
                handler={inputHandler}/>
                <Popper open={open} className="mx-2" anchorEl={anchorEl} placement={placement} transition>
                    {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper className="py-4 px-4">
                            <h5 className="pb-0 mb-2">Password Must</h5>
                            <ul className="list-unstyled passwordListing">
                                <li data-check={new RegExp("([0-9])+").test(payload.password) ? true : false}>Have one Number</li>
                                <li data-check={new RegExp("([A-Z])+").test(payload.password) ? true : false}>Have one uppercase character</li>
                                <li data-check={new RegExp("([@$!%*#?&])+").test(payload.password)}>Have one special character</li>
                                <li data-check={payload.password && payload.password.length >= 8}>Have 8 characters minimum</li>
                            </ul>
                        </Paper>
                    </Fade>
                    )}
                </Popper>
            </>
        }
        {
            step === 1 &&
            <>
                <MuiInput 
                options={{ 
                    name: "email", 
                    value: payload['email'], 
                    label: "Email", 
                    required: true,
                    type: "email",
                    disabled: true
                }}/>
                <MuiInput 
                options={{ 
                    name: "otp", 
                    value: payload['otp'], 
                    label: "Enter OTP", 
                    type: "number",
                    required: true
                }}
                className="mt-4"
                handler={inputHandler}/>
            </>
        }
        {
            step === 2 && 
            <>
                <MuiInput 
                options={{ 
                    name: "companyName", 
                    value: payload['companyName'], 
                    label: "Company Name", 
                    required: true
                }}
                handler={inputHandler}/>
                <MuiInput 
                options={{ 
                    name: "companyDescription", 
                    value: payload['companyDescription'], 
                    label: "Company Description", 
                    required: true,
                    row: 3
                }}
                className="mt-4"
                handler={inputHandler}/>
                <MuiInput 
                options={{ 
                    name: "taxIdentificationCode", 
                    value: payload['taxIdentificationCode'], 
                    label: "Company Tax ID", 
                    required: true
                }}
                className="mt-4"
                handler={inputHandler}/>
                <MuiInput 
                options={{ 
                    name: "url", 
                    value: payload['url'], 
                    label: "Website", 
                    required: false
                }}
                error={errorLoad['url'] ? true : false}
                helperText={errorLoad['url'] || ""}
                className="mt-4"
                handler={inputHandler}/>
                <MuiInput 
                options={{ 
                    name: "contactPhoneNumber", 
                    value: payload['contactPhoneNumber'], 
                    label: "Phone number", 
                    required: true
                }}
                error={errorLoad['contactPhoneNumber'] ? true : false}
                helperText={errorLoad['contactPhoneNumber'] || ""}
                className="mt-4"
                handler={inputHandler}/>
            </>
        }
        {
            step === 3 && 
            <>
            <MuiInput 
            options={{ 
                name: "officeAndBuildingNumber", 
                value: payload['officeAndBuildingNumber'], 
                label: "Address Line 1", 
                required: true
            }}
            className="mt-4"
            handler={inputHandler}/>
            <MuiInput 
            options={{ 
                name: "streetOrLocalityName", 
                value: payload['streetOrLocalityName'], 
                label: "Address Line 2", 
                required: false
            }}
            className="mt-4"
            handler={inputHandler}/>
            <MuiInput 
            options={{ 
                name: "city", 
                value: payload['city'], 
                label: "City", 
                required: true
            }}
            className="mt-4"
            handler={inputHandler}/>
            <MuiNativeSelect
            options={{ 
                name: "state", 
                value: payload['state'], 
                label: "State", 
                required: true, 
                list: stateCodeList
            }} 
            handler={inputHandler}
            className="mr-2 w-100 mt-4"
            />
            <MuiInput 
            options={{ 
                name: "zipCode", 
                value: payload['zipCode'], 
                label: "Zip Code", 
                required: true
            }}
            className="mt-4"
            handler={inputHandler}/>
            <MuiNativeSelect
            options={{ 
                name: "country", 
                value: countryList.length === 1 ? countryList[0].value : payload['country'], 
                label: "Country", 
                required: true, 
                list: countryList
            }} 
            handler={inputHandler}
            className="mr-2 w-100 mt-4"
            />
            </>
        }
        {
            step === 4 && 
            <>
            <MuiInput 
            options={{ 
                name: "account_holder_name", 
                value: payload['account_holder_name'], 
                label: "Account Holder Name", 
                required: true
            }}
            className="mt-4"
            handler={inputHandler}/>
            <MuiInput 
            options={{ 
                name: "account_number", 
                value: payload['account_number'], 
                label: "Account Number", 
                required: true
            }}
            className="mt-4"
            handler={inputHandler}/>
            <MuiInput 
            options={{ 
                name: "confirm_account_number", 
                value: payload['confirm_account_number'], 
                label: "Confirm Account Number", 
                required: true
            }}
            error={errorLoad['confirm_account_number'] ? true : false}
            helperText={errorLoad['confirm_account_number'] || ""}
            className="mt-4"
            handler={inputHandler}/>
            <MuiInput 
            options={{ 
                name: "routing_number", 
                value: payload['routing_number'], 
                label: "Routing Number", 
                required: true
            }}
            className="mt-4"
            handler={inputHandler}/>
            <MuiInput 
            options={{ 
                name: "confirm_routing_number", 
                value: payload['confirm_routing_number'], 
                label: "Confirm Routing Number", 
                required: true
            }}
            error={errorLoad['confirm_routing_number'] ? true : false}
            helperText={errorLoad['confirm_routing_number'] || ""}
            className="mt-4"
            handler={inputHandler}/>
            <MuiNativeSelect 
            options={{ 
                name: "account_holder_type", 
                value: payload['account_holder_type'], 
                label: "Account Holder Type", 
                required: true, 
                list: [{ name: "Individual", value: 'individual' }, { name: "Company", value: 'company' }]
            }} 
            handler={inputHandler}
            className="mr-2 w-100 mt-4"
            />
            </>
        }
        </React.Fragment>
    )
})