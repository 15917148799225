import React, { useEffect, useState } from 'react'
import { headerStyles } from '../../_styles/mui';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink, useHistory, withRouter } from 'react-router-dom';
import _ from 'lodash'
import dataset from '../../_config/dataset'
import { setEditRecord, triggerModal } from '../../_actions/common'
import { connect } from 'react-redux';
import { CONSTANTS } from '../../_utils/_constants';

const useStyles = (theme) => headerStyles(theme)

function OrganisationHead({
    routeType,
    resourceId,
    operationType,
    match,
    triggerModal,
    reduxTools,
    reduxState,
    storeDetails,
    setEditRecord,
    ...rest
}) {
    const classes = useStyles();
    const history = useHistory()
    const [details, setDetails] = useState({})
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if(routeType) {
            setDetails(dataset.topHead[routeType]({
                reduxTools,
                ...reduxState,
                storeDetails,
                currentParams: {...match.params} 
            }))
        }
    }, [routeType, reduxTools, reduxState, storeDetails])

    useEffect(() => {
        // triggerModal({})
    }, [routeType])

    const modalTrigger = (e, type) => {
        e.preventDefault()
        setAnchorEl(null);
        if(!type || type === 'backdropClick') {
            return;
        }
        let payload = {
            type,
            show: true,
            hide: true
        }
        if(type === CONSTANTS.routeType.EMPLOYEE) {
            payload = { ...payload, subtype: CONSTANTS.operation.ROADBLOCK, size: "md", matchLoad: match }
        }
        if(type === CONSTANTS.routeType.ONSTORE) {
            payload = { ...payload, subtype: CONSTANTS.operation.ADD }
        }
        if(type === CONSTANTS.routeType.PROFILE || type === CONSTANTS.routeType.PYSTORE) {
            payload = { editMode: true }
        }
        triggerModal({...payload})
    }
    
    const takeMeBack = () => {
        if(routeType === CONSTANTS.routeType.PROFILE || routeType === CONSTANTS.routeType.STORE) {
            setEditRecord(undefined, cb => {
                if(cb && cb.value) {
                    if(window.confirm("Do you really want to exit? All your progress will be lost")) {
                        history.goBack()
                    }
                }
                else {
                    history.goBack()
                }
            })
        }
        else if(routeType === CONSTANTS.routeType.PYSTORE) {
            if(window.confirm("Do you really want to exit? All your progress will be lost")) {
                history.goBack()
            }
        }
        else {
            history.goBack()
        }
    }

    return (
        <React.Fragment>
            <div className="d-flex w-100 justify-content-between flex-wrap">
                <div className="d-flex flex-row align-items-center">
                    <div className="btn-wrap my-auto">
                        {/* {details.backButton ? <Button className="rmv-outline" onClick={e => history.goBack()}><i className="material-icons">keyboard_backspace</i></Button> : ""} */}
                        {details.backButton ? <Button className="rmv-outline" onClick={e => takeMeBack()}><img src={`assets/icons/back.svg`} className="img-fluid"/></Button> : ""}
                    </div>
                    <div className="d-flex flex-column">
                        <h1 className="d-flex align-items-center mb-0 fsize-48">
                            {details.title}
                        </h1>
                        { details.subtitle && <h6 className="text-muted fsize-16">{details.subtitle}</h6> }
                    </div>
                </div>
                {
                    !_.isEmpty(details.button) && !details.button.redirect ? 
                    ( details.button.children ? 
                        <div className="btn-wrap ml-auto">
                            <Button variant="contained" aria-controls="home-menu" 
                            aria-haspopup="true" onClick={handleClick} type={details.button.buttonType || "type"}
                            className={`${classes.themeButton} d-flex align-items-center toLower stripBtn`}>
                                {details.button && details.button.mi ? <i className="material-icons mr-2">{details.button.mi}</i> : ""}
                                {details.button && details.button.img ? <img src={`assets/icons/${details.button.img}`} className="img-fluid mr-2"/> : ""}
                                {details.button ? details.button.name : ""}
                            </Button>
                            { details.button && details.button.drop ?
                                <Menu
                                    id="home-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left"
                                    }}
                                    getContentAnchorEl={null}
                                    onClose={modalTrigger}
                                >
                                {
                                    details.button.children && details.button.children.length && _.map(details.button.children, (o, i) => {
                                        return (
                                            <MenuItem key={i} onClick={e => modalTrigger(e, o.type)} className={classes.customMenuItem}>
                                                {
                                                    o.redirect ? 
                                                    <NavLink className={classes.defaultNavLink} to={`/dashboard${o.link}`}>{o.name}</NavLink> : 
                                                    o.name
                                                }
                                            </MenuItem>
                                        )
                                    })
                                }
                                </Menu> : ''
                            }
                        </div> :  
                        ( details.button.buttonType === "submit" ? 
                        <div className="btn-wrap ml-auto">
                            <Button variant="contained" aria-controls="home-menu" 
                            aria-haspopup="true" onClick={handleClick} type={details.button.buttonType || "type"}
                            className={`${classes.themeButton} d-flex align-items-center toLower stripBtn`}>
                                {details.button && details.button.mi ? <i className="material-icons mr-2">{details.button.mi}</i> : ""}
                                {details.button && details.button.img ? <img src={`assets/icons/${details.button.img}`} className="img-fluid mr-2"/> : ""}
                                {details.button ? details.button.name : ""}
                            </Button>
                        </div> : 
                        <div className="btn-wrap ml-auto">
                            <Button variant="contained" aria-controls="home-menu" 
                            aria-haspopup="true" onClick={e => modalTrigger(e, details.button.type)} type={details.button.buttonType || "type"}
                            className={`${classes.themeButton} d-flex align-items-center toLower stripBtn`}>
                                {details.button && details.button.mi ? <i className="material-icons mr-2">{details.button.mi}</i> : ""}
                                {details.button && details.button.img ? <img src={`assets/icons/${details.button.img}`} className="img-fluid mr-2"/> : ""}
                                {details.button ? details.button.name : ""}
                            </Button>
                        </div>)
                        ) : ( 
                        !_.isEmpty(details.button) && details.button.redirect ?     
                            <div className="btn-wrap ml-auto">
                                <NavLink className={classes.defaultNavLink} to={`/dashboard${details.button.link}`}>
                                    <Button variant="contained" aria-controls="home-menu" 
                                    aria-haspopup="true" onClick={handleClick}
                                    className={`${classes.themeButton} d-flex align-items-center toLower stripBtn`}>
                                        {details.button && details.button.mi ? <i className="material-icons mr-2">{details.button.mi}</i> : ""}
                                        {details.button && details.button.img ? <img src={`assets/icons/${details.button.img}`} className="img-fluid mr-2"/> : ""}
                                        {details.button ? details.button.name : ""}
                                    </Button>
                                </NavLink>
                            </div> 
                            : ""
                        )
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        reduxTools: state.tools || {},
        reduxState: state
    }
}

export default connect(mapStateToProps, { triggerModal, setEditRecord })(withRouter(OrganisationHead))