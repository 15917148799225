import React, { useEffect } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { connect } from 'react-redux';

function SnackApp({
    alertPayload
}) {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(alertPayload.show) {
            enqueueSnackbar(alertPayload.message, { variant: alertPayload.variant || "default" })
        }
    }, [alertPayload, enqueueSnackbar])

    return (
        <React.Fragment></React.Fragment>
    );
}

function GlobalSnack({
    alertPayload
}) {
    return <SnackbarProvider maxSnack={3} autoHideDuration={4000} anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
        <SnackApp alertPayload={alertPayload}/>
    </SnackbarProvider>
}

const mapStateToProps = state => {
    return {
        alertPayload: state.alert || {}
    }
}

export default connect(mapStateToProps)(GlobalSnack)