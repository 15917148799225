import _ from 'lodash'

export const CONSTANTS = {
    store: {
        add: `/store/add`,
        physical: `/store/physical/add`,
        online: `/store/online/add`
    },
    url: {
        employees: {
            add: 'employment/employee/add',
            edit: 'employment/employee/edit/designation',
            remove: 'employment/employee/remove',
            get: 'employment/employee', // storeId & userId as queryParam
            list: 'employment/employee/list',
            search: 'user/search/list'
        },
        organisation: {
            signup: `organisation/signup`,
            verify: `organisation/register/verification/email/otp/verify`,
            final: `organisation/register/company-details/`,
            login: `organisation/login`,
            details: `organisation/me`,
            forgot: `organisation/forgot-password/otp/send`,
            resend: `organisation/register/verification/email/otp/resend`,
            reset: `organisation/forgot-password/reset`,
            stripe: `https://api.stripe.com/v1/tokens`
        },
        home: {
            list: `store/list`
        },
        profile: {
            0: `organisation/update/company-details`,
            1: `organisation/update/banking-details`,
            2: `organisation/update/company-details`,
            3: `organisation/update/location-details`
        },
        store: {
            0: `store/:storeId/update/basic-details`,   // replace storeId with actual storeId
            1: `store/:storeId/update/banking-details`, // replace storeId with actual storeId
            2: `store/:storeId/update/company-details`, // replace storeId with actual storeId
            3: `store/:storeId/update/location-details`, // replace storeId with actual storeId,
            get: `store/get`
        },
        transaction: {
            list: `transaction/list/store-redeemed`
        }
    },
    operation: {
        ADD: `add`,
        EDIT: `edit`,
        VIEW: `view`,
        BLOCK: `block`,
        REMOVE: `remove`,
        ROADBLOCK: `roadblock`,
        SEARCH: `search`,
        LIST: `list`
    },
    routeType: {
        HOME: `home`,
        STORE: `store`,
        PYSTORE: `physical-store`,
        ONSTORE: `online-store`,
        EMPLOYEE: `employees`,
        TRANSACTION: `transaction`,
        PROFILE: `profile`
    },
    registration: {
        UNREGISTERED : 1,
        EMAIL_VERIFIED : 2,
        COMPANY_DETAILS : 3,
        COMPANY_ADDRESS : 4,
        FINAL : 5,
        VERIFIER: "verified"
    },
    stripe_key: process.env.REACT_APP_STRIPE_KEY,
    regex: {
        url: new RegExp('^(((ftp|http|https):\\/\\/)?[a-zA-Z0-9]+(\\.[a-zA-Z0-9]+)+.*)$'),
        password: new RegExp('^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$'),
        email: new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'),
        voucherRegex: new RegExp('^(dd_voucher_).*$'),
        phoneRegex: new RegExp('^\\+(?:[0-9] ?){6,14}[0-9]$')
    }
}

export const ageRangeCodeList = () => async (dispatch, store) => {
    return new Promise((resolve, reject) => {
        let ageCodes = store().records && store().records.constants ? store().records.constants.userAgeGroupList : []
        let customCodeList = _.map(ageCodes, (e,i) => {
            return {
                name: e.description,
                value: i
            }
        })
        if(_.isEmpty(customCodeList)) {
            reject([])
            return
        }
        resolve(customCodeList)
        return
    })
}

export const stateCodeListItem = (state = '', listCountry = false, callback) => async (dispatch, store) => {
    if(store().records && store().records.constants && store().records.constants.listLocations) {
        if(listCountry) {
            callback(_.map(_.uniqBy(store().records.constants.listLocations, 'country'), (o,i) => {
                return {
                    name: o.country,
                    value: o.country
                }
            }))
            return
        }
        if(state) {
            return callback(_.find(store().records.constants.listLocations, { state: state }) ? _.find(store().records.constants.listLocations, { state: state })['code'] : "")
        }
        callback(_.map(store().records.constants.listLocations, (o,i) => {
            return {
                name: o.state,
                value: o.state
            }
        }))
        return
    }
    callback([])
} 

export const genderCodeList = () => async (dispatch, store) => {
    return new Promise((resolve, reject) => {
        let genderCode = store().records && store().records.constants ? store().records.constants.userGenderList : []
        let customCodeList = _.map(genderCode, (e,i) => {
            return {
                name: e.description,
                value: i
            }
        })
        if(_.isEmpty(customCodeList)) {
            reject([])
            return
        }
        resolve(customCodeList)
        return
    })
}

export const positionList = () => async (dispatch, store) => {
    return new Promise((resolve, reject) => {
        let jobCode = store().records && store().records.constants ? store().records.constants.userJobDesignationList : []
        let customCodeList = _.map(jobCode, (e,i) => {
            return {
                name: e.description,
                value: i
            }
        })
        if(_.isEmpty(customCodeList)) {
            reject([])
            return
        }
        resolve(customCodeList)
        return
    })
}

export const renderAgeRangeCodeList = value => {
    let d = _.find(ageRangeCodeList, { value })
    return d.name || "NA"
}

export const renderGenderCodeList = value => {
    let d = _.find(genderCodeList, { value })
    return d.name || "NA"
}

export const renderPositionList = value => {
    let d = _.find(positionList, { value }) || {}
    return d.name || "NA"
}