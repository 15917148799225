import React from 'react'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { convertNormalOptions } from '../../_utils/_handlers';
import { HomePageStyles } from '../../_styles/mui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash'
import { MenuItem } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = (theme) => HomePageStyles(theme)

export const MuiInput = ({options, handler, className, helperText, error = false, handleClick}) => {
    const classes = useStyles();

    let restOptions = {}
    if(options.name === "password") {
        restOptions = {
            onFocus: e => handleClick(e),
            onBlur: e => handleClick(e)
        }
    }

    return (
        <TextField name={options.name} type={options.type || "text"} onChange={handler} required={options.required || false}
            id={convertNormalOptions({ name: options.name })} label={options.label || "Placeholder"}
            variant="outlined" className={`${classes.textField} customInputClass ${className}`} 
            disabled={options.disabled || false} value={options.value || ""}
            rows={options.row || 1}
            multiline={options.row > 1 ? true : false}
            autoComplete={`${Math.random() * 10 + 1}`}
            helperText={helperText || ""}
            error={error}
            InputProps={{
                startAdornment: options.name === "contactPhoneNumber" ? (
                    <InputAdornment position="start">
                        [+1]
                    </InputAdornment>
                ) : "",
            }}
            {...restOptions}
        />
    )
}

export const MuiCheckbox = ({options, handler, className}) => {
    return (
        <FormControlLabel
            onChange={(e, v) => handler({ target: { type: "checkbox", name: options.name, value: v } })}
            className={className || ""}
            checked={options.value || false}
            control={
                <Checkbox color="default" />
            }
            label={options.label || "label"}
            labelPlacement="end"
            disabled={options.disabled || false}
        />
    )
}

export const MuiSelect = ({options, handler, className}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false)

    return (
        <Autocomplete
            id={convertNormalOptions({ name: options.name })}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            className={className || ""}
            getOptionLabel={(option) => option.name || ""}
            options={options.list || []}
            onChange={(e, v) => {
                handler({ target: { type: "select", ...v, name: options.name, protoData: {...v} } }, "MuiSelect")
            }}
            loading={options.loading || false}
            value={_.find(options.list, { value: options.value }) || {}}
            disabled={options.disabled || false}
            onKeyUp={e => {
                if(options.selectiveSearch && options.selectiveSearch === true) {
                    options.changeSearch(e.target.value)
                }
            }}
            renderInput={(params) => {
                return (
                <TextField
                    label={options.label}
                    name={options.name}
                    required={options.required || false}
                    {...params}
                    className={`${classes.textField} customInputClass`}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {options.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    autoComplete={'off'}
                />
                )
            }}
        />
    );
}

export const MuiNativeSelect = ({ options, handler, className }) => {
    const classes = useStyles();
    return (
        <FormControl variant="outlined" className={`${classes.formControl} ${className} ${classes.textField} customInputClass`}>
            <InputLabel>{options.label}</InputLabel>
            <Select
                className={`${classes.textField} customInputClass`}
                disabled={options.disabled || false}
                required={options.required || false}
                value={options.value || ""}
                name={options.name}
                onChange={handler}
                label={options.label}
            >
                {
                    options && options.list && Object.keys(options.list).map(o => {
                        return (
                            <MenuItem key={options.list[o]['value']} value={options.list[o]['value']}>
                                {options.list[o]['name']}
                            </MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    )
}
